export const SET_DASHBOARD_LOADING= "SET_DASHBOARD_LOADING";
export const SET_ALERTS_LOADING= "SET_ALERTS_LOADING";


export const GET_DASHBOARD = "GET_DASHBOARD";
export const GET_DASHBOARD_SUCCESS = "GET_DASHBOARD_SUCCESS";
export const GET_DASHBOARD_FAILURE = "GET_DASHBOARD_FAILURE";

export const GET_DASHBOARD_OFFERS = "GET_DASHBOARD_OFFERS";
export const GET_DASHBOARD_OFFERS_SUCCESS = "GET_DASHBOARD_OFFERS_SUCCESS";
export const GET_DASHBOARD_OFFERS_FAILURE = "GET_DASHBOARD_OFFERS_FAILURE";

export const GET_OFFER_NOT_SET = "GET_OFFER_NOT_SET";
export const GET_OFFER_NOT_SET_SUCCESS = "GET_OFFER_NOT_SET_SUCCESS";
export const GET_OFFER_NOT_SET_FAILURE = "GET_OFFER_NOT_SET_FAILURE";

export const GET_ALERTS = "GET_ALERTS";
export const GET_ALERTS_SUCCESS = "GET_ALERTS_SUCCESS";
export const GET_ALERTS_FAILURE = "GET_ALERTS_FAILURE";

export const GET_HEARTBEAT = "GET_HEARTBEAT";
export const GET_HEARTBEAT_SUCCESS = "GET_HEARTBEAT_SUCCESS";
export const GET_HEARTBEAT_FAILURE = "GET_HEARTBEAT_FAILURE";
