import { put, all, takeLatest } from "redux-saga/effects";
import { toast } from "react-toastify";
import * as actionTypes from "./actionTypes";
import APIV2 from "../../Axios/AxiosV2";

function* FetchSaga() {
  try {
    yield put({ type: actionTypes.SET_ADVERTISERS_LOADING });
    const { data } = yield APIV2.get("/advertiser/getAll");

    if (data) {
      yield put({
        type: actionTypes.FETCH_ADVERTISERS_SUCCESS,
        payload: data.data,
      });
    } else {
      yield put({
        type: actionTypes.FETCH_ADVERTISERS_FAILURE,
        errorMessage: data.message,
      });
    }
  } catch (error) {
    yield put({
      type: actionTypes.FETCH_ADVERTISERS_FAILURE,
      errorMessage: "Unable to export.",
    });
  }
}

function* addAdvertiserShoppingSaga({ obj }) {
  try {
    yield put({ type: actionTypes.SET_ADVERTISERS_LOADING });
    const { data } = yield APIV2.post("/advertiser/create", obj);
    yield put({ type: actionTypes.FETCH_ADVERTISERS });

    if (data.success) {
      toast.success("Successfully added advertiser.");
      yield put({
        type: actionTypes.SHOPPING_OFFER_ADVERTISERS_SUCCESS,
        payload: data.data,
      });
      yield put({
        type: actionTypes.SET_ADVERTISERS_REDIRECT_TRUE,
      });
    } else {
      toast.error(data.data);
      yield put({
        type: actionTypes.SHOPPING_OFFER_ADVERTISERS_FAILURE,
        errorMessage: data.message,
      });
    }
  } catch (data) {
    toast.error("Not submitted");
    yield put({
      type: actionTypes.SHOPPING_OFFER_ADVERTISERS_FAILURE,
      errorMessage: "not loaded.",
    });
  }
}

function* getAdvertiserDhappaSaga() {
  try {
    yield put({ type: actionTypes.SET_ADVERTISERS_LOADING });
    const { data } = yield APIV2.get(
      "https://us-central1-dhappa-j.cloudfunctions.net/getAdvertisers"
    );
    if (data) {
      yield put({
        type: actionTypes.GET_ADVERTISERS_SUCCESS,
        payload: data.advertisers,
      });
    } else {
      yield put({
        type: actionTypes.GET_ADVERTISERS_FAILURE,
        errorMessage: data.advertisers.message,
      });
    }
  } catch (advertisers) {
    yield put({
      type: actionTypes.GET_ADVERTISERS_FAILURE,
      errorMessage: "not loaded.",
    });
  }
}

function* getAccountInfoSaga() {
  try {
    // eslint-disable-next-line no-unused-vars
    yield put({ type: actionTypes.SET_ADVERTISERS_LOADING });
    const data = yield APIV2.post(
      "https://www.googleapis.com/identitytoolkit/v3/relyingparty/getAccountInfo?key=YOUR_API_KEY"
    );
  } catch (data) {}
}

function* getOobConfirmSaga() {
  try {
    yield put({ type: actionTypes.SET_ADVERTISERS_LOADING });
    yield APIV2.post(
      "https://www.googleapis.com/identitytoolkit/v3/relyingparty/getOobConfirmationCode?key=YOUR_API_KEY"
    );
  } catch (data) {
    yield put({
      type: actionTypes.GET_ADVERTISERS_FAILURE,
      errorMessage: "not loaded.",
    });
  }
}

function* SignUpAdvertiserSaga({ payload }) {
  try {
    yield put({ type: actionTypes.SET_ADVERTISERS_LOADING });
    const { data } = yield APIV2.post(
      "https://www.googleapis.com/identitytoolkit/v3/relyingparty/signupNewUser?key=YOUR_API_KEY",
      payload
    );
    yield put({ type: actionTypes.GET_ACCOUNT_INFO });
    yield put({ type: actionTypes.GET_OOB_CONFIRMATION });

    if (data) {
      yield put({
        type: actionTypes.SIGNUP_ADVERTISER_SUCCESS,
        payload: data,
      });
    } else {
      yield put({
        type: actionTypes.SIGNUP_ADVERTISER_FAILURE,
        errorMessage: data.message,
      });
    }
  } catch (data) {
    yield put({
      type: actionTypes.SIGNUP_ADVERTISER_FAILURE,
      errorMessage: "not loaded.",
    });
  }
}

function* deleteShoppingAdvertiserSaga({ obj }) {
  try {
    yield put({ type: actionTypes.SET_ADVERTISERS_LOADING });
    const { data } = yield APIV2.delete(`/advertiser/delete/${obj}`);
    yield put({ type: actionTypes.FETCH_ADVERTISERS });

    if (data.success) {
      toast.success("Successfully deleted advertiser.");
      yield put({
        type: actionTypes.DELETE_SHOPPING_ADVERTISER_SUCCESS,
        payload: data,
      });
    } else {
      yield put({
        type: actionTypes.DELETE_SHOPPING_ADVERTISER_FAILURE,
        errorMessage: data.message,
      });
    }
  } catch (data) {
    yield put({
      type: actionTypes.DELETE_SHOPPING_ADVERTISER_FAILURE,
      errorMessage: "not loaded.",
    });
  }
}

function* verifyAdvertiser() {
  try {
    yield put({ type: actionTypes.SET_ADVERTISERS_LOADING });
    const { data } = yield APIV2.get("/advertiser/verify");
    if (data.success) {
      yield put({
        type: actionTypes.VERIFY_ADVERTISER_SUCCESS,
        payload: data.data,
      });
    } else {
      yield put({
        type: actionTypes.VERIFY_ADVERTISER_FAILURE,
        errorMessage: data.message,
      });
    }
  } catch (data) {
    yield put({
      type: actionTypes.VERIFY_ADVERTISER_FAILURE,
      errorMessage: "not loaded.",
    });
  }
}

function* getAdvertiserByIdSaga({ id }) {
  try {
    yield put({ type: actionTypes.SET_ADVERTISERS_LOADING });
    const { data } = yield APIV2.get(`/advertiser/getById/${id}`);
    if (data) {
      yield put({
        type: actionTypes.GET_ADVERTISER_BY_ID_SUCCESS,
        payload: data.data,
      });
    } else {
      yield put({
        type: actionTypes.GET_ADVERTISER_BY_ID_FAILURE,
        errorMessage: data.message,
      });
    }
  } catch (error) {
    yield put({
      type: actionTypes.GET_ADVERTISER_BY_ID_FAILURE,
      errorMessage: "not loaded.",
    });
  }
}

function* editAdvertiserSaga({ payload }) {
  try {
    yield put({ type: actionTypes.SET_ADVERTISERS_LOADING });
    const { data } = yield APIV2.put(`/advertiser/update/${payload._id}`, {
      ...payload,
    });
    if (data) {
      toast.success("Edit advertiser submitted successfully");
      yield put({
        type: actionTypes.EDIT_ADVERTISER_SUCCESS,
        payload: data.data,
      });
      yield put({
        type: actionTypes.SET_ADVERTISERS_REDIRECT_TRUE,
      });
    } else {
      toast.success(data.message);

      yield put({
        type: actionTypes.EDIT_ADVERTISER_FAILURE,
        errorMessage: data.message,
      });
    }
  } catch (error) {
    yield put({
      type: actionTypes.EDIT_ADVERTISER_FAILURE,
      errorMessage: "not loaded.",
    });
  }
}
function* redirectSaga({ payload }) {
  try {
    yield put({
      type: actionTypes.SET_ADVERTISERS_REDIRECT_FALSE_SUCCESS,
    });
  } catch (error) {
    console.log(error);
  }
}

function* AdvertiserDataSaga() {
  yield all([takeLatest(actionTypes.FETCH_ADVERTISERS, FetchSaga)]);
  yield all([
    takeLatest(
      actionTypes.SHOPPING_ADVERTISERS_OFFER,
      addAdvertiserShoppingSaga
    ),
  ]);
  yield all([takeLatest(actionTypes.GET_ADVERTISERS, getAdvertiserDhappaSaga)]);
  yield all([takeLatest(actionTypes.GET_ACCOUNT_INFO, getAccountInfoSaga)]);
  yield all([takeLatest(actionTypes.GET_OOB_CONFIRMATION, getOobConfirmSaga)]);
  yield all([takeLatest(actionTypes.SIGNUP_ADVERTISER, SignUpAdvertiserSaga)]);
  yield all([
    takeLatest(
      actionTypes.DELETE_SHOPPING_ADVERTISER,
      deleteShoppingAdvertiserSaga
    ),
  ]);
  yield all([takeLatest(actionTypes.VERIFY_ADVERTISER, verifyAdvertiser)]);
  yield all([
    takeLatest(actionTypes.GET_ADVERTISER_BY_ID, getAdvertiserByIdSaga),
  ]);
  yield all([takeLatest(actionTypes.EDIT_ADVERTISER, editAdvertiserSaga)]);
  yield all([
    takeLatest(actionTypes.SET_ADVERTISERS_REDIRECT_FALSE, redirectSaga),
  ]);
}

export default AdvertiserDataSaga;
