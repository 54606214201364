import React from "react";
const ContainerTag = React.lazy(() =>
  import("../Pages/ContainerTag/ContainerTag.jsx")
);
const PermissionList = React.lazy(() =>
  import("../Pages/Users/PermissionList.jsx")
);
const Permissions = React.lazy(() => import("../Pages/Users/Permissions.jsx"));
const Audit = React.lazy(() => import("../Pages/Audit/Audit.jsx"));
const Dashboard = React.lazy(() => import("../Pages/Dashboard/Dashboard.jsx"));
const Offers = React.lazy(() => import("../Pages/Offers/Offers.jsx"));
const GlobalConditions = React.lazy(() =>
  import("../Pages/GlobalConditions/GlobalConditions.jsx")
);
const GeoTargets = React.lazy(() =>
  import("../Pages/GeoTargets/GeoTargets.jsx")
);
const AddEditGeoTargets = React.lazy(() =>
  import("../Pages/GeoTargets/AddEditGeoTargets.jsx")
);
const ReportFileSystem = React.lazy(() =>
  import("../Pages/Reports/ReportFileSystem/ReportFileSystem.jsx")
);
const AddEditOffer = React.lazy(() =>
  import("../Pages/Offers/AddEditOffer.jsx")
);
const Report = React.lazy(() => import("../Pages/Reports/Reports.jsx"));
const Users = React.lazy(() => import("../Pages/Users/Users.jsx"));
const Advertisers = React.lazy(() =>
  import("../Pages/Advertisers/Advertisers.jsx")
);
const AddUsersShopping = React.lazy(() =>
  import("../Components/Users/AddUser.jsx")
);
const addAdvertiser = React.lazy(() =>
  import("../Components/Advertisers/AddAdvertiser.jsx")
);
const AddUserDhappa = React.lazy(() =>
  import("../Components/Users/AddUserDhappa.jsx")
);
const Statistics = React.lazy(() =>
  import("../Pages/Statistics/Statistics.jsx")
);
const AuditDetails = React.lazy(() =>
  import("../Pages/Audit/AuditDetails.jsx")
);
const AutomationLogic = React.lazy(() =>
  import("../Pages/AutomationLogic/AutomationLogic.jsx")
);


const authProtectedRoutes = [
  {
    path: "/admin/dashboard",
    component: Dashboard,
    router_slug: "Summary_view",
  },
  {
    path: "/admin/dashboard/statistics",
    component: Dashboard,
    router_slug: "Statistics_view",
  },
  {
    path: "/admin/dashboard/statistics/:tab",
    component: Dashboard,
    router_slug: "Statistics_view",
  },
  {
    path: "/admin/dashboard/general",
    component: Dashboard,
    router_slug: "General_view",
  },
  {
    path: "/admin/dashboard/daily-clicks",
    component: Dashboard,
    router_slug: "DailyClicks_view",
  },
  {
    path: "/admin/dashboard/caps",
    component: Dashboard,
    router_slug: "Caps_view",
  },
  {
    path: "/admin/dashboard/offernotset",
    component: Dashboard,
    router_slug: "Offer_not_set_view",
  },
  {
    path: "/admin/dashboard/alerts",
    component: Dashboard,
    router_slug: "Alerts_view",
  },

  {
    path: "/admin/dashboard/alerts/:tab",
    component: Dashboard,
    router_slug: "Alerts_view",
  },

  {
    path: "/admin/offers",
    component: Offers,
    router_slug: "Offer_view",
  },
  {
    path: "/admin/offer/addOffer",
    component: AddEditOffer,
    router_slug: "Offer_create",
  },
  {
    path: "/admin/offer/addOffer/:conditionKey",
    component: AddEditOffer,
    router_slug: "Offer_create",
  },
  {
    path: "/admin/offer/addOffer/:conditionKey/frauddetection",
    component: AddEditOffer,
    router_slug: "Offer_create",
  },
  {
    path: "/admin/offer/addOffer/:conditionKey/tracking",
    component: AddEditOffer,
    router_slug: "Offer_create",
  },
  {
    path: "/admin/offer/addOffer/:conditionKey/destination",
    component: AddEditOffer,
    router_slug: "Offer_create",
  },

  {
    path: "/admin/offer/addOffer/:conditionKey/fallback-traffic",
    component: AddEditOffer,
    router_slug: "Offer_create",
  },

  {
    path: "/admin/offer/edit/:id",
    component: AddEditOffer,
    router_slug: "Offer_view",
  },
  {
    path: "/admin/offer/edit/:id/:conditionKey",
    component: AddEditOffer,
    router_slug: "Offer_view",
  },
  {
    path: "/admin/offer/edit/:id/:conditionKey/frauddetection",
    component: AddEditOffer,
    router_slug: "Offer_view",
  },
  {
    path: "/admin/offer/edit/:id/:conditionKey/sub-2",
    component: AddEditOffer,
    router_slug: "Offer_view",
  },
  {
    path: "/admin/offer/edit/:id/:conditionKey/automation-rules",
    component: AddEditOffer,
    router_slug: "Offer_view",
  },
  {
    path: "/admin/offer/edit/:id/:conditionKey/tracking",
    component: AddEditOffer,
    router_slug: "Offer_view",
  },
  {
    path: "/admin/offer/edit/:id/:conditionKey/destination",
    component: AddEditOffer,
    router_slug: "Offer_view",
  },
  {
    path: "/admin/offer/edit/:id/:conditionKey/fallback-traffic",
    component: AddEditOffer,
    router_slug: "Offer_create",
  },
  {
    path: "/admin/offer/edit/:id/:conditionKey/others",
    component: AddEditOffer,
    router_slug: "Offer_view",
  },

  {
    path: "/admin/audit",
    component: Audit,
    router_slug: "Audit_view",
  },
  {
    path: "/admin/AuditDetails/:id",
    component: AuditDetails,
    router_slug: "Audit_view",
  },

  {
    path: "/admin/AuditDetails/:id/:condition",
    component: AuditDetails,
    router_slug: "Audit_view",
  },

  {
    path: "/admin/AuditDetails/:id/:condition/tracking",
    component: AuditDetails,
    router_slug: "Audit_view",
  },

  {
    path: "/admin/AuditDetails/:id/:condition/destination",
    component: AuditDetails,
    router_slug: "Audit_view",
  },

  {
    path: "/admin/AuditDetails/:id/:condition/adbFraudDetection",
    component: AuditDetails,
    router_slug: "Audit_view",
  },
  // {
  //   path: "/admin/Tools",
  //   component: Tools,
  //   router_slug:"Tools_view"
  // },

  {
    path: "/admin/report/reportdata",
    component: Report,
    router_slug: "Reports_view",
  },
  {
    path: "/admin/report/exportdata",
    component: Report,
    router_slug: "Reports_view",
  },
  {
    path: "/admin/report/filesystem",
    component: ReportFileSystem,
    router_slug: "Reports_view",
  },
  {
    path: "/admin/globalconditions",
    component: GlobalConditions,
    router_slug: "Global_Condition_view",
  },
  {
    path: "/admin/geotargets",
    component: GeoTargets,
    router_slug: "Geo_Targets_view",
  },
  {
    path: "/admin/geotargets/create",
    component: AddEditGeoTargets,
    router_slug: "Geo_Targets_view",
  },
  {
    path: "/admin/geotargets/edit/:id",
    component: AddEditGeoTargets,
    router_slug: "Geo_Targets_view",
  },
  {
    path: "/admin/users",
    component: Users,
    role: "ADMIN",
    router_slug: "Users_view",
  },
  {
    path: "/admin/advertisers",
    component: Advertisers,
    role: "ADMIN",
    router_slug: "Advertisers_view",
  },
  {
    path: "/admin/permission",
    component: PermissionList,
  },
  {
    path: "/admin/group-permission/create",
    component: Permissions,
    router_slug: "Users_create",
  },
  {
    path: "/admin/group-permission/:permissionID",
    component: Permissions,
  },
  {
    path: "/admin/users/addUsersShopping",
    component: AddUsersShopping,
    router_slug: "Users_create",
  },
  {
    path: "/admin/advertisers/addAdvertiser",
    component: addAdvertiser,
    router_slug: "Advertisers_create",
  },
  {
    path: "/admin/users/edit/:userId",
    component: AddUsersShopping,
    router_slug: "Users_create",
  },
  {
    path: "/admin/advertisers/edit/:userId",
    component: addAdvertiser,
    router_slug: "Advertisers_create",
  },

  {
    path: "/admin/users/addUserDhappa",
    component: AddUserDhappa,
    router_slug: "Users_create",
  },
  {
    path: "/admin/statistics",
    component: Statistics,
    router_slug: "Statistics_view",
  },
  {
    path: "/admin/containertag",
    component: ContainerTag,
    router_slug: "Container_Tag_view",
  },
  {
    path: "/admin/containertag/:id",
    component: ContainerTag,
    router_slug: "Container_Tag_view",
  },
  {
    path: "/admin/dashboard/heartbeat",
    component: Dashboard,
    router_slug: "HeartBeat_view",
  },
    {
    path: "/admin/automation_logic",
    component: AutomationLogic,
    router_slug:"Automation_Logic_view"
  },

];

export default authProtectedRoutes;
