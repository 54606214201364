import React from "react";
import { NavDropdown } from "react-bootstrap";
import { FaSignOutAlt } from "react-icons/fa";
import { useSelector } from "react-redux";

import { Nav, NavLink, Bars, NavMenu, NavBtn } from "./NavbarElements.jsx";

const Navbar = () => {
  const userData = useSelector((state) => state.UserData);
  const handleLogout = () => {
    window.localStorage.clear();
    window.location.reload();
  };

  return (
    <>
      <div className="navbarContainer">
        <Nav className="navbar">
          <NavLink to="/admin/dashboard">
            <img src="/logo.png" alt="Logo" width="40" height="50" />
          </NavLink>
          <Bars />
          <NavMenu>
            {Object.keys(userData.permissions).filter(
              (item) =>
                item.includes("Statistics") ||
                item.includes("General") ||
                item.includes("Exhausted") ||
                item.includes("Offer_not_set") ||
                item.includes("Alerts") ||
                item.includes("HeartBeat") ||
                item.includes("IPQS_Hits")
            ).length ? (
              <NavLink to="/admin/dashboard">Dashboard</NavLink>
            ) : null}
            {Object.keys(userData.permissions).filter(
              (item) => item.includes("Offer") && !item.includes("Offer_not")
            ).length ? (
              <NavLink to="/admin/offers">Offer</NavLink>
            ) : null}
            {Object.keys(userData.permissions).filter((item) =>
              item.includes("Global_Condition")
            ).length ||
            Object.keys(userData.permissions).filter((item) =>
              item.includes("Geo_Targets")
            ).length ? (
              <NavDropdown
                title="Global Configuration"
                id="basic-nav-dropdown"
                style={{ fontSize: "18px", padding: "0 16px" }}
              >
                {Object.keys(userData.permissions).filter((item) =>
                  item.includes("Global_Condition")
                ).length ? (
                  <NavDropdown.Item href="/admin/globalconditions">
                    Conditions
                  </NavDropdown.Item>
                ) : null}

                {Object.keys(userData.permissions).filter((item) =>
                  item.includes("Geo_Targets")
                ).length ? (
                  <NavDropdown.Item href="/admin/geotargets">
                    Geo Targets
                  </NavDropdown.Item>
                ) : null}
                 {Object.keys(userData.permissions).filter((item) =>
                  item.includes("Automation_Logic")
                ).length ? (
                  <NavDropdown.Item href="/admin/automation_logic">
                   Automation Logic
                  </NavDropdown.Item>
                ) : null}
              </NavDropdown>
            ) : null}
            {Object.keys(userData.permissions).filter((item) =>
              item.includes("Report")
            ).length ? (
              <NavDropdown
                title="Reports"
                id="basic-nav-dropdown"
                style={{ fontSize: "18px", padding: "0 16px" }}
              >
                <NavDropdown.Item href="/admin/report/exportdata">
                  Reports
                </NavDropdown.Item>
                <NavDropdown.Item href="/admin/report/filesystem">
                  Files
                </NavDropdown.Item>
              </NavDropdown>
            ) : null}

            {userData.type === "ADMIN" &&
              (Object.keys(userData.permissions).filter((item) =>
                item.includes("User")
              ).length ||
                Object.keys(userData.permissions).filter((item) =>
                  item.includes("User")
                ).length) && (
                <NavDropdown
                  title="Users"
                  id="basic-nav-dropdown"
                  style={{ fontSize: "18px", padding: "0 16px" }}
                >
                  {Object.keys(userData.permissions).filter((item) =>
                    item.includes("User")
                  ).length ? (
                    <NavDropdown.Item href="/admin/permission">
                      Permissions
                    </NavDropdown.Item>
                  ) : null}
                  {Object.keys(userData.permissions).filter((item) =>
                    item.includes("User")
                  ).length ? (
                    <NavDropdown.Item href="/admin/users">
                      User
                    </NavDropdown.Item>
                  ) : null}
                </NavDropdown>
              )}
            {Object.keys(userData.permissions).filter((item) =>
              item.includes("Advertiser")
            ).length ? (
              <NavLink to="/admin/advertisers">Advertisers</NavLink>
            ) : null}
            {Object.keys(userData.permissions).filter((item) =>
              item.includes("Audit")
            ).length ? (
              <NavLink to="/admin/audit">Audit</NavLink>
            ) : null}
             {Object.keys(userData.permissions).filter((item) =>
              item.includes("User")
            ).length ? (
              <NavLink to="/admin/containertag">Container Tag</NavLink>
            ) : null}
            
              

          </NavMenu>
          <NavBtn style={{ cursor: "pointer" }} onClick={() => handleLogout()}>
            <FaSignOutAlt />
          </NavBtn>
        </Nav>
      </div>
    </>
  );
};
export default Navbar;
