import * as actionTypes from "./actionTypes";

export const FetchAction = () => ({
  type: actionTypes.FETCH,
});

export const addUsersShoppingAction = (obj) => ({
  type: actionTypes.SHOPPING_OFFER,
  obj,
});

export const deleteShoppingUserAction = (obj) => ({
  type: actionTypes.DELETE_SHOPPING_USER,
  obj,
});

// Dhappa Actions

export const getUsersAction = () => ({
  type: actionTypes.GET_USERS,
});

export const getUserById =(id)=>({
  type:actionTypes.GET_USER_BY_ID,
  id
})

export const SignUpUserAction = (obj) => ({
  type: actionTypes.SIGNUP_USER,
  payload: obj,
});

export const getAccountInfoAction = () => ({
  type: actionTypes.GET_ACCOUNT_INFO,
});

export const getOobConfirmAction = () => ({
  type: actionTypes.GET_OOB_CONFIRMATION,
});

export const VerifyUserAction = (token) => ({
  type: actionTypes.VERIFY_USER,
  payload: token,
});

export const editUserAction = (data) => ({
  type: actionTypes.EDIT_USER,
  payload: data,
});
