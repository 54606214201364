/* eslint-disable react-hooks/exhaustive-deps */
import "./App.css";
import React, { Suspense, useMemo } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import authProtectedRoutes from "./Routes/routes";
import Login from "./Pages/Login/Login.jsx";
import "./Assets/Scss/index.scss";
import Navbar from "./Layout/Navbar.jsx";
import { useDispatch, useSelector } from "react-redux/es/exports";
import { useEffect } from "react";
import { VerifyUserAction } from "./Store/Users/actions";
import AccessDenied from "./Layout/AccessDenied";

function App() {
  const dispatch = useDispatch();
  const { permissions } = useSelector((state) => state.UserData);
  const checkPermissions = (router_slug) => {
    if (!router_slug.includes(",")) return false;

    let routerPermissions = router_slug.split(",");
    for (let slug of routerPermissions) {
      if (permissions[slug]) {
        return true;
      }
    }
  };

  // console.log(permissions,"permissions")

  const privateRoutes = useMemo(
    () =>
      authProtectedRoutes.map(
        ({ component: Component, path, role, router_slug }, key) => {
          if (
            !router_slug ||
            permissions[router_slug] ||
            checkPermissions(router_slug)
          ) {
            return (
              <Route
                path={path}
                key={key}
                element={
                  !localStorage.getItem("token") ? (
                    <Navigate to={{ pathname: "/login" }} />
                  ) : (
                    <>
                      <Navbar />
                      <Component />
                    </>
                  )
                }
              />
            );
          } else {
            return (
              <Route
                path={path}
                key={key}
                element={
                  <>
                    <Navbar />
                    {
                      Object.keys(permissions).length !== 0 &&  <AccessDenied />
                    }
                   
                  </>
                }
              />
            );
          }
        }
      ),
    [checkPermissions, permissions]
  );

  useEffect(() => {
    dispatch(VerifyUserAction());
  }, [dispatch]);
  return (
    <div className="App">
      <Suspense fallback={<div></div>}>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={true}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnHover
          theme="colored"
        />

        {localStorage.getItem("token") ? (
          <Routes>
            {privateRoutes}

            <Route
              path="*"
              element={<Navigate to={{ pathname: "/admin/dashboard" }} />}
            />
          </Routes>
        ) : (
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route
              path="*"
              element={<Navigate to={{ pathname: "/login" }} />}
            />
          </Routes>
        )}
      </Suspense>
    </div>
  );
}

export default App;
