export const GET_FILES = "GET_FILES";
export const GET_FILES_SUCCESS = "GET_FILES_SUCCESS";
export const GET_FILES_FAILURE = "GET_FILES_FAILURE";

export const EDIT_FILE = "EDIT_FILE";
export const EDIT_FILE_SUCCESS = "EDIT_FILE_SUCCESS";
export const EDIT_FILE_FAILURE = "EDIT_FILE_FAILURE";

export const DELETE_FILE = "DELETE_FILE";
export const DELETE_FILE_SUCCESS = "DELETE_FILE_SUCCESS";
export const DELETE_FILE_FAILURE = "DELETE_FILE_FAILURE";

export const FETCH_URL = "FETCH_URL";
export const FETCH_URL_SUCCESS = "FETCH_URL_SUCCESS";
export const FETCH_URL_FAILURE = "FETCH_URL_FAILURE"
export const CLEAR_CSV_DETAILS = "CLEAR_CSV_DETAILS"

export const SET_FILES_DATA_LOADING = "SET_FILES_DATA_LOADING";
