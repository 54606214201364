export const FETCH = "FETCH";
export const FETCH_SUCCESS = "FETCH_SUCCESS";
export const FETCH_FAILURE = "FETCH_FAILURE";

export const GET_USERS = "GET_USERS";
export const GET_USERS_SUCCESS = "GET_USERS_SUCCESS";
export const GET_USERS_FAILURE = "GET_USERS_FAILURE";

export const GET_USER_BY_ID = "GET_USER_BY_ID";
export const GET_USER_BY_ID_SUCCESS = "GET_USER_BY_ID_SUCCESS";
export const GET_USER_BY_ID_FAILURE = "GET_USER_BY_ID_FAILURE";

export const EDIT_USER = "EDIT_USER";
export const EDIT_USER_SUCCESS = "EDIT_USER_SUCCESS";
export const EDIT_USER_FAILURE = "EDIT_USER_FAILURE";

export const SHOPPING_OFFER = "SHOPPING_OFFER";
export const SHOPPING_OFFER_SUCCESS = "SHOPPING_OFFER_SUCCESS";
export const SHOPPING_OFFER_FAILURE = "SHOPPING_OFFER_FAILURE";

export const DELETE_SHOPPING_USER = "DELETE_SHOPPING_USER";
export const DELETE_SHOPPING_USER_SUCCESS = "DELETE_SHOPPING_USER_SUCCESS";
export const DELETE_SHOPPING_USER_FAILURE = "DELETE_SHOPPING_USER_FAILURE";

// DHappa action types

export const SIGNUP_USER = "SIGNUP_USER";
export const SIGNUP_USER_SUCCESS = "SIGNUP_USER_SUCCESS";
export const SIGNUP_USER_FAILURE = "SIGNUP_USER_FAILURE";

export const VERIFY_USER = "VERIFY_USER";
export const VERIFY_USER_SUCCESS = "VERIFY_USER_SUCCESS";
export const VERIFY_USER_FAILURE = "VERIFY_USER_FAILURE";

export const GET_ACCOUNT_INFO = "GET_ACCOUNT_INFO";
export const GET_ACCOUNT_INFO_SUCCESS = "GET_ACCOUNT_INFO_SUCCESS";
export const GET_ACCOUNT_INFO_FAILURE = "GET_ACCOUNT_INFO_FAILURE";

export const GET_OOB_CONFIRMATION = "GET_OOB_CONFIRMATION";
export const GET_OOB_CONFIRMATION_SUCCESS = "GET_OOB_CONFIRMATION_SUCCESS";
export const GET_OOB_CONFIRMATION_FAILURE = "GET_OOB_CONFIRMATION_FAILURE";

export const SET_USERS_LOADING = "SET_USERS_LOADING";

