export const FETCH_LOGIC_DATA = "FETCH_LOGIC_DATA";
export const FETCH_LOGIC_DATA_SUCCESS = "FETCH_LOGIC_DATA_SUCCESS";
export const FETCH_LOGIC_DATA_FAILURE = "FETCH_LOGIC_DATA_FAILURE";

export const ADD_LOGIC_DATA = "ADD_LOGIC_DATA";
export const ADD_LOGIC_DATA_SUCCESS = "ADD_LOGIC_DATA_SUCCESS";
export const ADD_LOGIC_DATA_FAILURE = "ADD_LOGIC_DATA_FAILURE";

export const DELETE_LOGIC_DATA = "DELETE_LOGIC_DATA";
export const DELETE_LOGIC_DATA_SUCCESS = "DELETE_LOGIC_DATA_SUCCESS";
export const DELETE_LOGIC_DATA_FAILURE = "DELETE_LOGIC_DATA_FAILURE";

export const UPDATE_LOGIC_DATA = "UPDATE_LOGIC_DATA";
export const UPDATE_LOGIC_DATA_SUCCESS = "UPDATE_LOGIC_DATA_SUCCESS";
export const UPDATE_LOGIC_DATA_FAILURE = "UPDATE_LOGIC_DATA_FAILURE";


export const SET_LOGIC_LOADING = "SET_LOGIC_LOADING";


