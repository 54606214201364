import { combineReducers } from "redux";
import authReducer from "./Auth/reducer";
import Dashboard from "./Dashboard/reducer";
import Offers from "./Offers/reducer";
import GlobalConditions from "./GlobalConditions/reducer";
import ExportData from "./ExportData/reducer";
import UserData from "./Users/reducer";
import AdvertiserData from "./Advertisers/reducer";
import Statistics from "./Statistics/reducer";
import Audit from "./Audit/reducer";
import Collapse from "./Collapse/reducer";
import Roles from "./Roles/reducer";
import FileManager from "./FileManager/reducer";
import GeoTargetsReducer from './GeoTargets/reducer'
import Sub2Reducer from './Sub2/reducer'
import ContainerTag from "./ContainerTag/reducer";
import AutomaticLogic from "./AutomaticLogic/reducer"

const rootReducer = combineReducers({
  authReducer,
  Dashboard,
  Offers,
  GlobalConditions,
  ExportData,
  UserData,
  AdvertiserData,
  Collapse,
  Statistics,
  Audit,
  Roles,
  FileManager,
  GeoTargetsReducer,
  Sub2Reducer,
  ContainerTag,
  AutomaticLogic
});

export default rootReducer;
