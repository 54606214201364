/* eslint-disable default-case */
import * as actionTypes from "./actionTypes";

const initialState = {
  error: null,
  loading: false,
  token: "",
  containerTagData: [],
  singleContainerTag: {},
  errorMessage: "",
};

const ContainerTag = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_CONTAINER_TAG_LOADING:
      return {
        ...state,
        loading: true,
        error: false,
        errorMessage: "",
      };
    case actionTypes.ADD_CONTAINER_TAG_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
      };
    case actionTypes.ADD_CONTAINER_TAG_FAILURE:
    case actionTypes.GET_CONTAINER_TAG_FAILURE:
    case actionTypes.GET_SINGLE_CONTAINER_TAG_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.errorMessage,
      };
    case actionTypes.GET_CONTAINER_TAG_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        containerTagData: action.payload,
      };
    case actionTypes.GET_SINGLE_CONTAINER_TAG_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        singleContainerTag: action.payload,
      };
      default:
        return state;
  }
};

export default ContainerTag;
