import * as actionTypes from "./actionTypes";

const initialState = {
  error: false,
  errorMessage: "",
  data: {},
  dashboardOffers: [],
  loading: false,
  offerNotSet: [],
  alerts: {},
  alertsLoading: false,
  heartbeatData: [],
};

const Dashboard = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_DASHBOARD_LOADING:
      return {
        ...state,
        loading: true,
        error: false,
        errorMessage: "",
      };
    case actionTypes.GET_DASHBOARD_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        loading: false,
      };
    case actionTypes.GET_DASHBOARD_FAILURE:
      return {
        ...state,
        error: true,
        errorMessage: action.errorMessage,
      };
    case actionTypes.GET_DASHBOARD_OFFERS_SUCCESS:
      return {
        ...state,
        dashboardOffers: action.payload,
        loading: false,
      };
    case actionTypes.GET_DASHBOARD_OFFERS_FAILURE:
      return {
        ...state,
        error: true,
        errorMessage: action.errorMessage,
        loading: false,
      };
    case actionTypes.GET_OFFER_NOT_SET_SUCCESS:
      return {
        ...state,
        offerNotSet: action.payload,
        loading: false,
      };
    case actionTypes.GET_OFFER_NOT_SET_FAILURE:
      return {
        ...state,
        error: true,
        errorMessage: action.errorMessage,
        loading: false,
      };
    case actionTypes.SET_ALERTS_LOADING:
      return {
        ...state,
        alertsLoading: true,
      };
    case actionTypes.GET_ALERTS_SUCCESS:
      return {
        ...state,
        alerts: action.payload,
        alertsLoading: false,
      };
    case actionTypes.GET_HEARTBEAT_SUCCESS:
      return {
        ...state,
        loading: false,
        heartbeatData: action.payload,
      };
    case actionTypes.GET_HEARTBEAT_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.errorMessage,
      }
    case actionTypes.GET_ALERTS_FAILURE:
      return {
        ...state,
        error: true,
        errorMessage: action.errorMessage,
        alertsLoading: false,
      };
    default:
      return state;
  }
};

export default Dashboard;
