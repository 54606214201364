import * as actionTypes from "./actionTypes";

const initialState = {
  error: false,
  errorMessage: "",
  successMessage: "",
  loading: false,
  role:{},
  roleList: [],
};

const UserData = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_ROLES_LOADING:
      return {
        ...state,
        loading: true,
        error: false,
        errorMessage: "",
      };

    case actionTypes.CREATE_ROLES_SUCCESS:
      return {
        ...state,
        loading: false,
        successMessage: action.payload,
      };
    case actionTypes.CREATE_ROLES_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.errorMessage,
      };
    case actionTypes.GET_ROLES_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        roleList: action.payload,
      };
    case actionTypes.GET_ROLES_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.errorMessage,
      };
    case actionTypes.GET_ROLE_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        role: action.payload,
      };
    case actionTypes.GET_ROLE_BY_ID_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.errorMessage,
      };
    case actionTypes.EDIT_ROLE_SUCCESS:
      return {
        ...state,
        loading: false,
        successMessage: action.payload,
      };
    case actionTypes.EDIT_ROLE_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.errorMessage,
      };


    default:
      return state;
  }
};

export default UserData;
