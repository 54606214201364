export const GET_SUB2 = "GET_SUB2";
export const GET_SUB2_SUCCESS = "GET_SUB2_SUCCESS";
export const GET_SUB2_FAILURE = "GET_SUB2_FAILURE";

export const CREATE_SUB2 = "CREATE_SUB2"
export const CREATE_SUB2_SUCCESS = "CREATE_SUB2_SUCCESS"
export const CREATE_SUB2_FAILURE = "CREATE_SUB2_FAILURE"

export const SET_SUB2_LOADING = "SET_SUB2_LOADING";

export const DELETE_SELECTED_SUB2 = "DELETE_SELECTED_SUB2"
export const DELETE_SELECTED_SUB2_SUCCESS = "DELETE_SELECTED_SUB2_SUCCESS"
export const DELETE_SELECTED_SUB2_FAILURE = "DELETE_SELECTED_SUB2_FAILURE"

