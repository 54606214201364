import { put, all, takeLatest } from "redux-saga/effects";
import * as actionTypes from "./actionTypes";
import APIV2 from "../../Axios/AxiosV2";
import { toast } from "react-toastify";

function* getSub2Saga({ payload }) {
  try {
    yield put({ type: actionTypes.SET_SUB2_LOADING });
    const { data } = yield APIV2.get(`sub2/get/${payload.offerId}/${payload.conditionId}`);
    if (data.success) {
      yield put({
        type: actionTypes.GET_SUB2_SUCCESS,
        payload: data.data,
      });
    } else {
      yield put({
        type: actionTypes.GET_SUB2_FAILURE,
        errorMessage: data,
      });
    }
  } catch (error) {
    yield put({
      type: actionTypes.GET_SUB2_FAILURE,
      errorMessage: "Unable to get sub2.",
    });
  }
}

function* deleteSub2Saga({ payload }) {
  try {
    
    yield put({ type: actionTypes.SET_SUB2_LOADING });
    const { data } = yield APIV2.post("sub2/delete", {...payload});
    if (data.success) {
      toast.success("Delete success");
      yield put({ type: actionTypes.GET_SUB2, payload:{offerId: payload.offerId, conditionId: payload.conditionId} });
      yield put({
        type: actionTypes.DELETE_SELECTED_SUB2_SUCCESS,
        payload: data,
      });
    } else {
      toast.error("Unable to delete this!");
      yield put({
        type: actionTypes.DELETE_SELECTED_SUB2_FAILURE,
        errorMessage: "Unable to delete this!",
      });
    }
  } catch (error) {
    toast.error("Unable to delete this!");
    yield put({
      type: actionTypes.DELETE_SELECTED_SUB2_FAILURE,
      errorMessage: "Unable to delete this!",
    });
  }
}

function* createSub2Saga({ payload }) {
    try {
      yield put({ type: actionTypes.SET_SUB2_LOADING });
      const { data } = yield APIV2.post("sub2/create ", { ...payload });
      if (data.success) {
        toast.success("Successfully added.");
        yield put({ type: actionTypes.CREATE_SUB2_SUCCESS });
        yield put({ type: actionTypes.GET_SUB2, payload:{offerId: payload.offerId, conditionId: payload.conditionId} });
      } else {
        yield put({
          type: actionTypes.CREATE_SUB2_FAILURE,
          errorMessage: "Unable to add.",
        });
      }
    } catch (error) {
      toast.error("Unable to add.");
      yield put({
        type: actionTypes.CREATE_SUB2_FAILURE,
        errorMessage: "Unable to add.",
      });
    }
}

function* Sub2Saga() {
  yield all([
    takeLatest(actionTypes.CREATE_SUB2, createSub2Saga),
    takeLatest(actionTypes.GET_SUB2, getSub2Saga),
    takeLatest(actionTypes.DELETE_SELECTED_SUB2, deleteSub2Saga),
  ]);
}

export default Sub2Saga;
