import { put, all, takeLatest } from "redux-saga/effects";
import * as actionTypes from "./actionTypes";
import API from "../../Axios/Axios";
import APIV2 from "../../Axios/AxiosV2";

function* getDashboardSaga({ sort }) {
  try {
    yield put({ type: actionTypes.SET_DASHBOARD_LOADING });
    const { data } = yield APIV2.get(
      `dashboard/get-dashboard`
    );

    yield put({
      type: actionTypes.GET_DASHBOARD_SUCCESS,
      payload: data,
    });
  } catch (error) {
    yield put({
      type: actionTypes.GET_DASHBOARD_FAILURE,
      errorMessage: "Unable to load dashboard data.",
    });
  }
}
function* getDashboardOffersSaga() {
  try {
    yield put({ type: actionTypes.SET_DASHBOARD_LOADING });
    const { data } = yield API.get("/statistics-v2/weightage/dashboard/all");

    yield put({
      type: actionTypes.GET_DASHBOARD_OFFERS_SUCCESS,
      payload: data.data,
    });
  } catch (error) {
    yield put({
      type: actionTypes.GET_DASHBOARD_OFFERS_FAILURE,
      errorMessage: "Unable to load dashboard Offers.",
    });
  }
}
function* getOfferNotSetSaga() {
  try {
    yield put({ type: actionTypes.SET_DASHBOARD_LOADING });
    const { data } = yield API.get("/offers-not-set");

    yield put({
      type: actionTypes.GET_OFFER_NOT_SET_SUCCESS,
      payload: data.data,
    });
  } catch (error) {
    yield put({
      type: actionTypes.GET_OFFER_NOT_SET_FAILURE,
      errorMessage: "Unable to load  Offer Not Set List.",
    });
  }
}

function* getAlertsSaga() {
  try {
    yield put({ type: actionTypes.SET_ALERTS_LOADING });
    const { data } = yield API.get("alerts");

    yield put({
      type: actionTypes.GET_ALERTS_SUCCESS,
      payload: data.data,
    });
  } catch (error) {
    yield put({
      type: actionTypes.GET_ALERTS_FAILURE,
      errorMessage: "Unable to load Alerts List.",
    });
  }
}

function* getAllHeartbeatSaga() {
  try {
    yield put({ type: actionTypes.SET_DASHBOARD_LOADING });
    const { data } = yield APIV2.get("/dashboard/heartbeat");
    yield put({
      type: actionTypes.GET_HEARTBEAT_SUCCESS,
      payload: data.data,
    });
  } catch (error) {
     yield put({
      type: actionTypes.GET_HEARTBEAT_FAILURE,
      errorMessage: "Unable to load Heartbeat data.",
    });
  }
}

function* DashboardSaga() {
  yield all([takeLatest(actionTypes.GET_DASHBOARD, getDashboardSaga)]);
  yield all([takeLatest(actionTypes.GET_DASHBOARD_OFFERS, getDashboardOffersSaga)]);
  yield all([takeLatest(actionTypes.GET_OFFER_NOT_SET, getOfferNotSetSaga)]);
  yield all([takeLatest(actionTypes.GET_ALERTS, getAlertsSaga)]);
  yield all([takeLatest(actionTypes.GET_HEARTBEAT, getAllHeartbeatSaga)]);
}

export default DashboardSaga;