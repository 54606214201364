import { put, all, takeLatest } from "redux-saga/effects";
import { toast } from "react-toastify";
import * as actionTypes from "./actionTypes";
import APIV2 from "../../Axios/AxiosV2";
import axios from "axios";

function* getStatisticsSaga({ params }) {
  try {
    yield put({ type: actionTypes.SET_STATISTICS_LOADING });
    const { data } = yield APIV2.get("statistics-v2/all", { params: params });

    if (data.success) {
      yield put({
        type: actionTypes.GET_STATISTICS_SUCCESS,
        payload: data.data,
      });
    } else {
      yield put({
        type: actionTypes.GET_STATISTICS_FAILURE,
        errorMessage: "Unable to load Statistics.",
      });
    }
  } catch (error) {
    toast.error("Unable to load Statistics.");
    yield put({
      type: actionTypes.GET_STATISTICS_FAILURE,
      errorMessage: "Unable to load Statistics.",
    });
  }
}

function* getDailyStatisticsSaga({ params }) {
  try {
    yield put({ type: actionTypes.SET_STATISTICS_LOADING });
    const { data } = yield APIV2.get("statistics-v2/daily-statistics", {
      params: params,
    });

    if (data.success) {
      yield put({
        type: actionTypes.GET_DAILY_STATISTICS_SUCCESS,
        payload: data.data,
      });
    } else {
      yield put({
        type: actionTypes.GET_DAILY_STATISTICS_FAILURE,
        errorMessage: "Unable to load Statistics.",
      });
    }
  } catch (error) {
    toast.error("Unable to load Statistics.");
    yield put({
      type: actionTypes.GET_DAILY_STATISTICS_FAILURE,
      errorMessage: "Unable to load Statistics.",
    });
  }
}

function* getWeeklyStatisticsSaga({ params }) {
  try {
    yield put({ type: actionTypes.SET_STATISTICS_LOADING });
    const { data } = yield APIV2.get("statistics-v2/weekly-statistics", {
      params: params,
    });

    if (data.success) {
      yield put({
        type: actionTypes.GET_WEEKLY_STATISTICS_SUCCESS,
        payload: data.data,
      });
    } else {
      yield put({
        type: actionTypes.GET_WEEKLY_STATISTICS_FAILURE,
        errorMessage: "Unable to load Statistics.",
      });
    }
  } catch (error) {
    toast.error("Unable to load Statistics.");
    yield put({
      type: actionTypes.GET_WEEKLY_STATISTICS_FAILURE,
      errorMessage: "Unable to load Statistics.",
    });
  }
}

function* getMonthlyStatisticsSaga({ params }) {
  try {
    yield put({ type: actionTypes.SET_STATISTICS_LOADING });
    const { data } = yield APIV2.get("statistics-v2/monthly-statistics", {
      params: params,
    });

    if (data.success) {
      yield put({
        type: actionTypes.GET_MONTHLY_STATISTICS_SUCCESS,
        payload: data.data,
      });
    } else {
      yield put({
        type: actionTypes.GET_MONTHLY_STATISTICS_FAILURE,
        errorMessage: "Unable to load Statistics.",
      });
    }
  } catch (error) {
    toast.error("Unable to load Statistics.");
    yield put({
      type: actionTypes.GET_MONTHLY_STATISTICS_FAILURE,
      errorMessage: "Unable to load Statistics.",
    });
  }
}

function* getCountryStatisticsSaga({ params }) {
  try {
    yield put({ type: actionTypes.SET_STATISTICS_LOADING });
    const { data } = yield APIV2.get("statistics-v2/country-statistics", {
      params: params,
    });

    if (data.success) {
      yield put({
        type: actionTypes.GET_COUNTRY_STATISTICS_SUCCESS,
        payload: data.data,
      });
    } else {
      yield put({
        type: actionTypes.GET_COUNTRY_STATISTICS_FAILURE,
        errorMessage: "Unable to load Statistics.",
      });
    }
  } catch (error) {
    toast.error("Unable to load Statistics.");
    yield put({
      type: actionTypes.GET_COUNTRY_STATISTICS_FAILURE,
      errorMessage: "Unable to load Statistics.",
    });
  }
}
function* getDeviceStatisticsSaga({ params }) {
  try {
    yield put({ type: actionTypes.SET_STATISTICS_LOADING });
    const { data } = yield APIV2.get("statistics-v2/device-statistics", {
      params: params,
    });

    if (data.success) {
      yield put({
        type: actionTypes.GET_DEVICE_STATISTICS_SUCCESS,
        payload: data.data,
      });
    } else {
      yield put({
        type: actionTypes.GET_DEVICE_STATISTICS_FAILURE,
        errorMessage: "Unable to load Statistics.",
      });
    }
  } catch (error) {
    toast.error("Unable to load Statistics.");
    yield put({
      type: actionTypes.GET_DEVICE_STATISTICS_FAILURE,
      errorMessage: "Unable to load Statistics.",
    });
  }
}

function* getOsStatisticsSaga({ params }) {
  try {
    yield put({ type: actionTypes.SET_STATISTICS_LOADING });
    const { data } = yield APIV2.get("statistics-v2/os-statistics", {
      params: params,
    });

    if (data.success) {
      yield put({
        type: actionTypes.GET_OS_STATISTICS_SUCCESS,
        payload: data.data,
      });
    } else {
      yield put({
        type: actionTypes.GET_OS_STATISTICS_FAILURE,
        errorMessage: "Unable to load Statistics.",
      });
    }
  } catch (error) {
    toast.error("Unable to load Statistics.");
    yield put({
      type: actionTypes.GET_OS_STATISTICS_FAILURE,
      errorMessage: "Unable to load Statistics.",
    });
  }
}

function* getRejectionStatisticsSaga({ params }) {
  try {
    yield put({ type: actionTypes.SET_STATISTICS_LOADING });
    const { data } = yield APIV2.get("statistics-v2/rejection-statistics", {
      params: params,
    });

    if (data.success) {
      yield put({
        type: actionTypes.GET_REJECTION_STATISTICS_SUCCESS,
        payload: data.data,
      });
    } else {
      yield put({
        type: actionTypes.GET_REJECTION_STATISTICS_FAILURE,
        errorMessage: "Unable to load Statistics.",
      });
    }
  } catch (error) {
    toast.error("Unable to load Statistics.");
    yield put({
      type: actionTypes.GET_REJECTION_STATISTICS_FAILURE,
      errorMessage: "Unable to load Statistics.",
    });
  }
}

function* editExhostedCapSaga({ payload }) {
  try {
    yield put({ type: actionTypes.SET_STATISTICS_LOADING });
    const { data } = yield APIV2.post("/link/exhausted/editcap", payload);

    if (data.success) {
      yield put({
        type: actionTypes.EDIT_EXHAUSTED_CAP_SUCCESS,
        payload: data.data,
      });
      yield put({ type: actionTypes.GET_EXHAUSTED_LIST });
      toast.success("Cap Updated");
    } else {
      yield put({
        type: actionTypes.EDIT_EXHAUSTED_CAP_FAILURE,
        errorMessage: "Unable to edit cap.",
      });
    }
  } catch (error) {
    toast.error("Unable to edit cap");
    yield put({
      type: actionTypes.EDIT_EXHAUSTED_CAP_FAILURE,
      errorMessage: "Unable to edit cap",
    });
  }
}

function* getOriginListSaga({ category }) {
  try {
    const { data } = yield APIV2.get("/statistics-v2/get-origin-list", {
      params: { category: category },
    });
    if (data.success) {
      yield put({
        type: actionTypes.GET_ORIGIN_LIST_SUCCESS,
        payload: data.origin,
      });
    } else {
      yield put({
        type: actionTypes.GET_ORIGIN_LIST_FAILURE,
        errorMessage: "Unable to load Origin List.",
      });
    }
  } catch (error) {
    toast.error("Unable to load Origin List.");
    yield put({
      type: actionTypes.GET_ORIGIN_LIST_FAILURE,
      errorMessage: "Unable to load Origin List.",
    });
  }
}

function* getExhaustedListSaga() {
  try {
    yield put({ type: actionTypes.SET_STATISTICS_LOADING });
    const { data } = yield APIV2.get("dashboard/caps");
    if (data.success) {
      yield put({
        type: actionTypes.GET_EXHAUSTED_LIST_SUCCESS,
        payload: data.data,
      });
    } else {
      yield put({
        type: actionTypes.GET_EXHAUSTED_LIST_FAILURE,
        errorMessage: "Unable to load Exhausted List.",
      });
    }
  } catch (error) {
    toast.error("Unable to load Exhausted List.");
    yield put({
      type: actionTypes.GET_EXHAUSTED_LIST_FAILURE,
      errorMessage: "Unable to load Exhausted List.",
    });
  }
}

function* getDailyClicksSaga({ params }) {
  try {
    yield put({ type: actionTypes.SET_STATISTICS_LOADING });
    const { data } = yield APIV2.get("dashboard/click-data", {
      params: params,
    });
    if (data.success) {
      yield put({
        type: actionTypes.GET_DAILY_CLICKS_SUCCESS,
        payload: data.data,
      });
    } else {
      yield put({
        type: actionTypes.GET_DAILY_CLICKS_FAILURE,
        errorMessage: "Unable to load Daily Clicks.",
      });
    }
  } catch (error) {
    toast.error("Unable to load Statistics.");
    yield put({
      type: actionTypes.GET_DAILY_CLICKS_FAILURE,
      errorMessage: "Unable to load Daily Clicks.",
    });
  }
}

function* getAllIPQSHitsSaga({ params }) {
  try {
    yield put({ type: actionTypes.SET_STATISTICS_LOADING });
    const { data } = yield APIV2.get("statistics-v2/ipqs-hits", {
      params: params,
    });
    yield put({
      type: actionTypes.GET_IPQS_HITS_SUCCESS,
      payload: data.data,
    });
  } catch (error) {
    yield put({
      type: actionTypes.GET_IPQS_HITS_FAILURE,
      errorMessage: "Unable to load IPQS Hits data.",
    });
  }
}

function* getLostClickReasonsSaga({ params }) {
  try {
    yield put({ type: actionTypes.SET_LOST_CLICK_LOADING });
    const { data } = yield APIV2.get("statistics-v2/lost-click-reasons", {
      params: params,
    });
    yield put({
      type: actionTypes.GET_LOST_CLICK_REASONS_SUCCESS,
      payload: data.data,
    });
  } catch (error) {
    yield put({
      type: actionTypes.GET_LOST_CLICK_REASONS_FAILURE,
      errorMessage: "Unable to lost click reasons data.",
    });
  }
}

function* StatisticsSaga() {
  yield all([
    takeLatest(actionTypes.GET_STATISTICS, getStatisticsSaga),
    takeLatest(actionTypes.GET_ORIGIN_LIST, getOriginListSaga),
    takeLatest(actionTypes.GET_EXHAUSTED_LIST, getExhaustedListSaga),
    takeLatest(actionTypes.EDIT_EXHAUSTED_CAP, editExhostedCapSaga),
    takeLatest(actionTypes.GET_DAILY_STATISTICS, getDailyStatisticsSaga),
    takeLatest(actionTypes.GET_COUNTRY_STATISTICS, getCountryStatisticsSaga),
    takeLatest(actionTypes.GET_DEVICE_STATISTICS, getDeviceStatisticsSaga),
    takeLatest(actionTypes.GET_OS_STATISTICS, getOsStatisticsSaga),
    takeLatest(actionTypes.GET_DAILY_CLICKS, getDailyClicksSaga),
    takeLatest(
      actionTypes.GET_REJECTION_STATISTICS,
      getRejectionStatisticsSaga
    ),
    takeLatest(actionTypes.GET_IPQS_HITS, getAllIPQSHitsSaga),
    takeLatest(actionTypes.GET_WEEKLY_STATISTICS, getWeeklyStatisticsSaga),
    takeLatest(actionTypes.GET_MONTHLY_STATISTICS, getMonthlyStatisticsSaga),
    takeLatest(actionTypes.GET_LOST_CLICK_REASONS, getLostClickReasonsSaga),
  ]);
}

export default StatisticsSaga;
