import { put, all, takeLatest } from "redux-saga/effects";
import { toast } from "react-toastify";
import * as actionTypes from "./actionTypes";
import APIV2 from "../../Axios/AxiosV2";

function* FetchSaga() {
  try {
    yield put({ type: actionTypes.SET_USERS_LOADING });
    const { data } = yield APIV2.get("/user/fetch");

    if (data) {
      yield put({
        type: actionTypes.FETCH_SUCCESS,
        payload: data.data,
      });
    } else {
      yield put({
        type: actionTypes.FETCH_FAILURE,
        errorMessage: data.data.message,
      });
    }
  } catch (error) {
    yield put({
      type: actionTypes.FETCH_FAILURE,
      errorMessage: "Unable to export.",
    });
  }
}

function* addUserShoppingSaga({ obj }) {
  try {
    yield put({ type: actionTypes.SET_USERS_LOADING });
    const { data } = yield APIV2.post("/user/create", obj);
    yield put({ type: actionTypes.FETCH });

    if (data.success) {
      toast.success("Add user submitted successfully");
      yield put({
        type: actionTypes.SHOPPING_OFFER_SUCCESS,
      });
    } else {
      toast.error(data.data.message);
      yield put({
        type: actionTypes.SHOPPING_OFFER_FAILURE,
        errorMessage: data.message,

      });
    }
  } catch (data) {
    toast.error("Not submitted");
    yield put({
      type: actionTypes.SHOPPING_OFFER_FAILURE,
      errorMessage: "not loaded.",

    });
  }
}

function* getUserDhappaSaga() {
  try {
     yield put({ type: actionTypes.SET_USERS_LOADING });
    const { data } = yield APIV2.get(
      "https://us-central1-dhappa-j.cloudfunctions.net/getUsers"
    );
    if (data) {
      yield put({
        type: actionTypes.GET_USERS_SUCCESS,
        payload: data.users,
      });
    } else {
      yield put({
        type: actionTypes.GET_USERS_FAILURE,
        errorMessage: data.users.message,
      });
    }
  } catch (users) {
    yield put({
      type: actionTypes.GET_USERS_FAILURE,
      errorMessage: "not loaded.",
    });
  }
}

function* getAccountInfoSaga() {
  try {
    // eslint-disable-next-line no-unused-vars
    yield put({ type: actionTypes.SET_USERS_LOADING });
    const data = yield APIV2.post(
      "https://www.googleapis.com/identitytoolkit/v3/relyingparty/getAccountInfo?key=AIzaSyDXQ1goGPt7oh5LfYdSIsTNaHeoAF2pfW8"
    );
  } catch (data) {}
}

function* getOobConfirmSaga() {
  try {
    yield put({ type: actionTypes.SET_USERS_LOADING });
    yield APIV2.post(
      "https://www.googleapis.com/identitytoolkit/v3/relyingparty/getOobConfirmationCode?key=AIzaSyDXQ1goGPt7oh5LfYdSIsTNaHeoAF2pfW8"
    );

   
  } catch (data) {
    yield put({
      type: actionTypes.GET_USERS_FAILURE,
      errorMessage: "not loaded.",
    });
  }
}

function* SignUpUserSaga({ payload }) {
  try {
    yield put({ type: actionTypes.SET_USERS_LOADING });
    const { data } = yield APIV2.post(
      "https://www.googleapis.com/identitytoolkit/v3/relyingparty/signupNewUser?key=AIzaSyDXQ1goGPt7oh5LfYdSIsTNaHeoAF2pfW8",
      payload
    );
    yield put({ type: actionTypes.GET_ACCOUNT_INFO });
    yield put({ type: actionTypes.GET_OOB_CONFIRMATION });

    if (data) {
      yield put({
        type: actionTypes.SIGNUP_USER_SUCCESS,
        payload: data,
      });
    } else {
      yield put({
        type: actionTypes.SIGNUP_USER_FAILURE,
        errorMessage: data.message,
      });
    }
  } catch (data) {
    yield put({
      type: actionTypes.SIGNUP_USER_FAILURE,
      errorMessage: "not loaded.",
    });
  }
}

function* deleteShoppingUserSaga({ obj }) {
  try {
    yield put({ type: actionTypes.SET_USERS_LOADING });
    const { data } = yield APIV2.post("/user/delete", obj);
    yield put({ type: actionTypes.FETCH });

    if (data.success) {
      toast.success("Successfully deleted user");
      yield put({
        type: actionTypes.DELETE_SHOPPING_USER_SUCCESS,
        payload: data,
      });
    } else {
      yield put({
        type: actionTypes.DELETE_SHOPPING_USER_FAILURE,
        errorMessage: data.message,
      });
    }
  } catch (data) {
    yield put({
      type: actionTypes.DELETE_SHOPPING_USER_FAILURE,
      errorMessage: "not loaded.",
    });
  }
}

function* varifyUser(){
  try {
    yield put({ type: actionTypes.SET_USERS_LOADING });
    const { data } = yield APIV2.get("/user/verify");
    if (data.success) {
      yield put({
        type: actionTypes.VERIFY_USER_SUCCESS,
        payload: data.data,
      });
    } else {
      yield put({
        type: actionTypes.VERIFY_USER_FAILURE,
        errorMessage: data.message,
      });
    }
  } catch (data) {
    yield put({
      type: actionTypes.VERIFY_USER_FAILURE,
      errorMessage: "not loaded.",
    });
  }
}

function* getUserByIdSaga({id}) {
  try {
     yield put({ type: actionTypes.SET_USERS_LOADING });
     const { data } = yield APIV2.get(`/user/${id}`);
     if (data) {
      yield put({
        type: actionTypes.GET_USER_BY_ID_SUCCESS,
        payload: data.data,
      });
    } else {
      yield put({
        type: actionTypes.GET_USER_BY_ID_FAILURE,
        errorMessage: data.message,
      });
    }
  } catch (error) {
    yield put({
      type: actionTypes.GET_USER_BY_ID_FAILURE,
      errorMessage: "not loaded.",
    });
  }
}

function* editUserSaga({payload}) {
  try {
     yield put({ type: actionTypes.SET_USERS_LOADING });
     const { data } = yield APIV2.post(`/user/edit/${payload._id}`,{...payload});
     if (data) {
      toast.success("Edit user submitted successfully");

      yield put({
        type: actionTypes.EDIT_USER_SUCCESS,
        payload: data.data,
      });
    } else {
      toast.success(data.message);

      yield put({
        type: actionTypes.EDIT_USER_FAILURE,
        errorMessage: data.message,
      });
    }
  } catch (error) {
    yield put({
      type: actionTypes.EDIT_USER_FAILURE,
      errorMessage: "not loaded.",
    });
  }
}

function* UserDataSaga() {
  yield all([takeLatest(actionTypes.FETCH, FetchSaga)]);
  yield all([takeLatest(actionTypes.SHOPPING_OFFER, addUserShoppingSaga)]);
  yield all([takeLatest(actionTypes.GET_USERS, getUserDhappaSaga)]);
  yield all([takeLatest(actionTypes.GET_ACCOUNT_INFO, getAccountInfoSaga)]);
  yield all([takeLatest(actionTypes.GET_OOB_CONFIRMATION, getOobConfirmSaga)]);
  yield all([takeLatest(actionTypes.SIGNUP_USER, SignUpUserSaga)]);
  yield all([takeLatest(actionTypes.DELETE_SHOPPING_USER, deleteShoppingUserSaga),]);
  yield all([takeLatest(actionTypes.VERIFY_USER, varifyUser),]);
  yield all([takeLatest(actionTypes.GET_USER_BY_ID, getUserByIdSaga),]);
  yield all([takeLatest(actionTypes.EDIT_USER, editUserSaga),]);
}

export default UserDataSaga;
