export const GET_REQUEST = "GET_REQUEST";
export const GET_REQUEST_SUCCESS = "GET_REQUEST_SUCCESS";
export const GET_REQUEST_FAILURE = "GET_REQUEST_FAILURE";

export const EXPORT_LINK = "EXPORT_LINK";
export const EXPORT_LINK_SUCCESS = "EXPORT_LINK_SUCCESS";
export const EXPORT_LINK_FAILURE = "EXPORT_LINK_FAILURE";

export const GET_PRESETS = "GET_PRESETS";
export const GET_PRESETS_SUCCESS = "GET_PRESETS_SUCCESS";
export const GET_PRESETS_FAILURE = "GET_PRESETS_FAILURE";

export const ADD_PRESETS = "ADD_PRESETS";
export const ADD_PRESETS_SUCCESS = "ADD_PRESETS_SUCCESS";
export const ADD_PRESETS_FAILURE = "ADD_PRESETS_FAILURE";

export const UPDATE_PRESETS = "UPDATE_PRESETS";
export const UPDATE_PRESETS_SUCCESS = "UPDATE_PRESETS_SUCCESS";
export const UPDATE_PRESETS_FAILURE = "UPDATE_PRESETS_FAILURE";

export const DELETE_PRESETS = "DELETE_PRESETS";
export const DELETE_PRESETS_SUCCESS = "DELETE_PRESETS_SUCCESS";
export const DELETE_PRESETS_FAILURE = "DELETE_PRESETS_FAILURE";

export const SET_EXPORT_DATA_LOADING = "SET_EXPORT_DATA_LOADING";
