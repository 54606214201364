import * as actionTypes from "./actionTypes";

const initialState = {
  error: false,
  errorMessage: "",
  loading: false,
  fileDetails: {},
  files: [],
  tolalPages: 0,
  totalItems: 0,
  page: 0,
  showCsv:false,
  fileCsv:{},
};

const FileManager = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_FILES_DATA_LOADING:
      return {
        ...state,
        loading: true,
        error: false,
        errorMessage: "",
      };
    case actionTypes.GET_FILES_SUCCESS:
      return {
        ...state,
        loading: false,
        files: action.payload.data,
        tolalPages: action.payload.tolalPages,
        totalItems: action.payload.totalItems,
        page: action.payload.page,
      };
    case actionTypes.GET_FILES_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.errorMessage,
      };
    case actionTypes.DELETE_FILE_SUCCESS:
      return {
        ...state,
        loading: false,
        message: action.payload,
      };
    case actionTypes.EDIT_FILE_SUCCESS:
      return {
        ...state,
        loading: false,
        fileDetails: action.payload,
      };
    case actionTypes.DELETE_FILE_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.errorMessage,
      };
      case actionTypes.FETCH_URL_SUCCESS:
        return{
          ...state,
          loading: false,
          fileCsv: action.payload,
          showCsv:true
        };
        case actionTypes.FETCH_URL_FAILURE:
          return{
            ...state,
            loading: false,
            error: true,
            showCsv:false
          };
          case actionTypes.CLEAR_CSV_DETAILS:
      return {
        ...state,
        fileCsv: {},
        showCsv:false,
      };
    default:
      return state;
  }
};

export default FileManager;
