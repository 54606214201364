export const GET_GEO_TARGETS = "GET_GEO_TARGETS";
export const GET_GEO_TARGETS_SUCCESS = "GET_GEO_TARGETS_SUCCESS";
export const GET_GEO_TARGETS_FAILURE = "GET_GEO_TARGETS_FAILURE";

export const GET_TARGET_TYPES = "GET_TARGET_TYPES";
export const GET_TARGET_TYPES_SUCCESS = "GET_TARGET_TYPES_SUCCESS";
export const GET_TARGET_TYPES_FAILURE = "GET_TARGET_TYPES_FAILURE";

export const DELETE_SELECTED_GEOTARGETS = "DELETE_SELECTED_GEOTARGETS";
export const DELETE_SELECTED_GEOTARGETS_SUCCESS =
  "DELETE_SELECTED_GEOTARGETS_SUCCESS";
export const DELETE_SELECTED_GEOTARGETS_FAILURE =
  "DELETE_SELECTED_GEOTARGETS_FAILURE";

export const DELETE_ALL_SELECTED_GEOTARGETS = "DELETE_ALL_SELECTED_GEOTARGETS";
export const DELETE_ALL_SELECTED_GEOTARGETS_SUCCESS =
  "DELETE_ALL_SELECTED_GEOTARGETS_SUCCESS";
export const DELETE_ALL_SELECTED_GEOTARGETS_FAILURE =
  "DELETE_ALL_SELECTED_GEOTARGETS_FAILURE";

export const IMPORT_GEOTARGETS_CSV = "IMPORT_GEOTARGETS_CSV";
export const IMPORT_GEOTARGETS_CSV_SUCCESS = "IMPORT_GEOTARGETS_CSV_SUCCESS";
export const IMPORT_GEOTARGETS_CSV_FAILURE = "IMPORT_GEOTARGETS_CSV_FAILURE";

export const CREATE_GEOTARGETS = "CREATE_GEOTARGETS";
export const CREATE_GEOTARGETS_SUCCESS = "CREATE_GEOTARGETS_SUCCESS";
export const CREATE_GEOTARGETS_FAILURE = "CREATE_GEOTARGETS_FAILURE";

export const GET_CANONICAL_NAME = "GET_CANONICAL_NAME";
export const GET_CANONICAL_NAME_SUCCESS = "GET_CANONICAL_NAME_SUCCESS";
export const GET_CANONICAL_NAME_FAILURE = "GET_CANONICAL_NAME_FAILURE";

export const GET_SINGLE_GEO_TARGET = "GET_SINGLE_GEO_TARGET";
export const GET_SINGLE_GEO_TARGET_SUCCESS = "GET_SINGLE_GEO_TARGET_SUCCESS";
export const GET_SINGLE_GEO_TARGET_FAILURE = "GET_SINGLE_GEO_TARGET_FAILURE";

export const EDIT_GEO_TARGET = "EDIT_GEO_TARGET";
export const EDIT_GEO_TARGET_SUCCESS = "EDIT_GEO_TARGET_SUCCESS";
export const EDIT_GEO_TARGET_FAILURE = "EDIT_GEO_TARGET_FAILURE";

export const SEARCH_GEO_TARGET = "SEARCH_GEO_TARGET";
export const SEARCH_GEO_TARGET_SUCCESS = "SEARCH_GEO_TARGET_SUCCESS";
export const SEARCH_GEO_TARGET_FAILURE = "SEARCH_GEO_TARGET_FAILURE";

export const SET_GEO_TARGETS_LOADING = "SET_GEO_TARGETS_LOADING";

export const TOGGLE_GEO_TARGETS = "TOGGLE_GEO_TARGETS";
export const TOGGLE_GEO_TARGETS_SUCCESS = "TOGGLE_GEO_TARGETS_SUCCESS";
export const TOGGLE_GEO_TARGETS_FAILURE = "TOGGLE_GEO_TARGETS_FAILURE";

export const GET_COUNTRIES = "GET_COUNTRIES";
export const GET_COUNTRIES_SUCCESS = "GET_COUNTRIES_SUCCESS";
export const GET_COUNTRIES_FAILURE = "GET_COUNTRIES_FAILURE";
