import { put, all, takeLatest } from "redux-saga/effects";
import { toast } from "react-toastify";
import * as actionTypes from "./actionTypes";
import APIV2 from "../../Axios/AxiosV2";

function* GetRolesListSaga() {
  try {
    yield put({ type: actionTypes.SET_ROLES_LOADING });
    const { data } = yield APIV2.get("/roles/getAll");

    if (data) {
      yield put({
        type: actionTypes.GET_ROLES_LIST_SUCCESS,
        payload: data.data,
      });
    } else {
      yield put({
        type: actionTypes.GET_ROLES_LIST_FAILURE,
        errorMessage: data.data.message,
      });
    }
  } catch (error) {
    yield put({
      type: actionTypes.GET_ROLES_LIST_FAILURE,
      errorMessage: "Unable to export.",
    });
  }
}

function* addRolesSaga({ obj }) {
  try {
    yield put({ type: actionTypes.SET_ROLES_LOADING });
    const { data } = yield APIV2.post("/roles/create", obj);

    if (data.success) {
      toast.success("Add Roles submitted successfully");
      yield put({
        type: actionTypes.CREATE_ROLES_SUCCESS,
      });
    } else {
      toast.error(data.data.message);
      yield put({
        type: actionTypes.CREATE_ROLES_FAILURE,
        errorMessage: data.message,
      });
    }
  } catch (data) {
    toast.error("Not submitted");
    yield put({
      type: actionTypes.CREATE_ROLES_FAILURE,
      errorMessage: "not loaded.",

    });
  }
}
function* deleteRolesSaga({ roleId }) {
  try {
    yield put({ type: actionTypes.SET_ROLES_LOADING });
    const { data } = yield APIV2.delete(`/roles/delete/${roleId}`);
    yield put({type: actionTypes.GET_ROLES_LIST});

    if (data.success) {
      toast.success("Role Deleted successfully");
      yield put({
        type: actionTypes.DELETE_ROLE_SUCCESS,
      });
    } else {
      toast.error(data.data.message);
      yield put({
        type: actionTypes.DELETE_ROLE_FAILURE,
        errorMessage: data.message,
      });
    }
  } catch (data) {
    toast.error("Not submitted");
    yield put({
      type: actionTypes.DELETE_ROLE_FAILURE,
      errorMessage: "not loaded.",

    });
  }
}
function* getRoleByIdSaga({ roleId }) {
  try {
    yield put({ type: actionTypes.SET_ROLES_LOADING });
    const { data } = yield APIV2.get(`/roles/getById/${roleId}`);
    if (data.success) {
      yield put({type: actionTypes.GET_ROLE_BY_ID_SUCCESS,payload:data.data});
    } else {
      toast.error(data.data.message);
      yield put({
        type: actionTypes.GET_ROLE_BY_ID_FAILURE,
        errorMessage: data.message,
      });
    }
  } catch (data) {
    toast.error("Not submitted");
    yield put({
      type: actionTypes.GET_ROLE_BY_ID_FAILURE,
      errorMessage: "not loaded.",

    });
  }
}
function* editRoleByIdSaga({ obj }) {
  try {
    yield put({ type: actionTypes.SET_ROLES_LOADING });
    const { data } = yield APIV2.post(`/roles/update/${obj.roleId}`,{name:obj.name,description:obj.description,permissions:obj.permissions});
    if (data.success) {
      toast.success("Role Edited successfully");
      yield put({type: actionTypes.EDIT_ROLE_SUCCESS});
    } else {
      toast.error(data.data.message);
      yield put({
        type: actionTypes.EDIT_ROLE_FAILURE,
        errorMessage: data.message,
      });
    }
  } catch (data) {
    toast.error("Not submitted");
    yield put({
      type: actionTypes.EDIT_ROLE_FAILURE,
      errorMessage: "not loaded.",

    });
  }
}


function* RolesDataSaga() {
  yield all([takeLatest(actionTypes.CREATE_ROLES, addRolesSaga)]);
  yield all([takeLatest(actionTypes.GET_ROLES_LIST, GetRolesListSaga)]);
  yield all([takeLatest(actionTypes.DELETE_ROLE,deleteRolesSaga)]);
  yield all([takeLatest(actionTypes.GET_ROLE_BY_ID,getRoleByIdSaga)]);
  yield all([takeLatest(actionTypes.EDIT_ROLE,editRoleByIdSaga)]);


}

export default RolesDataSaga;
