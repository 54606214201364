import { put, all, takeLatest } from "redux-saga/effects";
import * as actionTypes from "./actionTypes";
import APIV2 from "../../Axios/AxiosV2";
import { toast } from "react-toastify";

function* createContainerTag({ obj }) {
  try {
    yield put({ type: actionTypes.SET_CONTAINER_TAG_LOADING });
    const { data } = yield APIV2.post("/container-tag", obj);
    if (data.success) {
      toast.success("Container tag added successfully!");
      yield put({ type: actionTypes.ADD_CONTAINER_TAG_SUCCESS });
      yield put({ type: actionTypes.GET_CONTAINER_TAG });
    } else {
      toast.error(data.data.message);
      yield put({
        type: actionTypes.ADD_CONTAINER_TAG_FAILURE,
        errorMessage: "Unable to add container tag!",
      });
    }
  } catch (error) {
    toast.error("Unable to add container tag!");
    yield put({
      type: actionTypes.ADD_CONTAINER_TAG_FAILURE,
      errorMessage: "Unable to add container tag!",
    });
  }
}

function* getContainerTagListSaga() {
  try {
    yield put({ type: actionTypes.SET_CONTAINER_TAG_LOADING });
    const { data } = yield APIV2.get("/container-tag");
    if (data.success) {
      yield put({
        type: actionTypes.GET_CONTAINER_TAG_SUCCESS,
        payload: data.data,
      });
    } else {
      yield put({
        type: actionTypes.GET_CONTAINER_TAG_FAILURE,
        errorMessage: "Unable to load container tag list.",
      });
    }
  } catch (error) {
    yield put({
      type: actionTypes.GET_CONTAINER_TAG_FAILURE,
      errorMessage: "Unable to load container tag list.",
    });
  }
}

function* getContainerTagSaga({ params }) {
  try {
    yield put({ type: actionTypes.SET_CONTAINER_TAG_LOADING });
    const { data } = yield APIV2.get(`/container-tag/${params}`);
    if (data.success) {
      yield put({
        type: actionTypes.GET_SINGLE_CONTAINER_TAG_SUCCESS,
        payload: data.data,
      });
    } else {
      yield put({
        type: actionTypes.GET_SINGLE_CONTAINER_TAG_FAILURE,
        errorMessage: "Unable to fetch container tag.",
      });
    }
  } catch (error) {
    yield put({
      type: actionTypes.GET_SINGLE_CONTAINER_TAG_FAILURE,
      errorMessage: "Unable to fetch container tag.",
    });
  }
}

function* editContainerTagSaga({ params, obj }) {
  try {
    yield put({ type: actionTypes.SET_CONTAINER_TAG_LOADING });
    const { data } = yield APIV2.put(`/container-tag/${params}`, obj);
    if (data.success) {
      toast.success("Edited container tag successfully");
      yield put({
        type: actionTypes.EDIT_CONTAINER_TAG_SUCCESS,
        payload: data.data,
      });
    } else {
      toast.error(data.data.message);
      yield put({
        type: actionTypes.EDIT_CONTAINER_TAG_FAILURE,
        errorMessage: "Unable to edit container tag.",
      });
    }
  } catch (error) {
    toast.error("Unable to edit container tag.");
    yield put({
      type: actionTypes.EDIT_CONTAINER_TAG_FAILURE,
      errorMessage: "Unable to edit container tag.",
    });
  }
}

function* deleteContainerTagSaga({ params }) {
  try {
    yield put({ type: actionTypes.SET_CONTAINER_TAG_LOADING });
    const { data } = yield APIV2.delete(`/container-tag/${params}`);
    if (data.success) {
      toast.success(data.message);
      yield put({
        type: actionTypes.DELETE_CONTAINER_TAG_SUCCESS,
        payload: data.data,
      });
      yield put({ type: actionTypes.GET_CONTAINER_TAG });
    } else {
      toast.error(data.data.message);
      yield put({
        type: actionTypes.DELETE_CONTAINER_TAG_FAILURE,
        errorMessage: "Unable to delete container tag.",
      });
    }
  } catch (error) {
    toast.error("Unable to delete container tag.");
    yield put({
      type: actionTypes.DELETE_CONTAINER_TAG_FAILURE,
      errorMessage: "Unable to delete container tag.",
    });
  }
}

function* ContainerTagSaga() {
  yield all([takeLatest(actionTypes.ADD_CONTAINER_TAG, createContainerTag)]);
  yield all([
    takeLatest(actionTypes.GET_CONTAINER_TAG, getContainerTagListSaga),
  ]);
  yield all([
    takeLatest(actionTypes.GET_SINGLE_CONTAINER_TAG, getContainerTagSaga),
  ]);
  yield all([takeLatest(actionTypes.EDIT_CONTAINER_TAG, editContainerTagSaga)]);
  yield all([
    takeLatest(actionTypes.DELETE_CONTAINER_TAG, deleteContainerTagSaga),
  ]);
}
export default ContainerTagSaga;
