export const GET_STATISTICS = "GET_STATISTICS";
export const GET_STATISTICS_SUCCESS = "GET_STATISTICS_SUCCESS";
export const GET_STATISTICS_FAILURE = "GET_STATISTICS_FAILURE";

export const GET_ORIGIN_LIST = "GET_ORIGIN_LIST";
export const GET_ORIGIN_LIST_SUCCESS = "GET_ORIGIN_LIST_SUCCESS";
export const GET_ORIGIN_LIST_FAILURE = "GET_ORIGIN_LIST_FAILURE";

export const GET_EXHAUSTED_LIST = "GET_EXHAUSTED_LIST";
export const GET_EXHAUSTED_LIST_SUCCESS = "GET_EXHAUSTED_LIST_SUCCESS";
export const GET_EXHAUSTED_LIST_FAILURE = "GET_EXHAUSTED_LIST_FAILURE";

export const EDIT_EXHAUSTED_CAP = "EDIT_EXHAUSTED_CAP";
export const EDIT_EXHAUSTED_CAP_SUCCESS = "EDIT_EXHAUSTED_CAP_SUCCESS";
export const EDIT_EXHAUSTED_CAP_FAILURE = "EDIT_EXHAUSTED_CAP_FAILURE";

export const GET_DAILY_STATISTICS = "GET_DAILY_STATISTICS";
export const GET_DAILY_STATISTICS_SUCCESS = "GET_DAILY_STATISTICS_SUCCESS";
export const GET_DAILY_STATISTICS_FAILURE = "GET_DAILY_STATISTICS_FAILURE";

export const GET_COUNTRY_STATISTICS = "GET_COUNTRY_STATISTICS";
export const GET_COUNTRY_STATISTICS_SUCCESS = "GET_COUNTRY_STATISTICS_SUCCESS";
export const GET_COUNTRY_STATISTICS_FAILURE = "GET_COUNTRY_STATISTICS_FAILURE";

export const GET_DEVICE_STATISTICS = "GET_DEVICE_STATISTICS";
export const GET_DEVICE_STATISTICS_SUCCESS = "GET_DEVICE_STATISTICS_SUCCESS";
export const GET_DEVICE_STATISTICS_FAILURE = "GET_DEVICE_STATISTICS_FAILURE";

export const GET_WEEKLY_STATISTICS = "GET_WEEKLY_STATISTICS";
export const GET_WEEKLY_STATISTICS_SUCCESS = "GET_WEEKLY_STATISTICS_SUCCESS";
export const GET_WEEKLY_STATISTICS_FAILURE = "GET_WEEKLY_STATISTICS_FAILURE";

export const GET_MONTHLY_STATISTICS = "GET_MONTHLY_STATISTICS";
export const GET_MONTHLY_STATISTICS_SUCCESS = "GET_MONTHLY_STATISTICS_SUCCESS";
export const GET_MONTHLY_STATISTICS_FAILURE = "GET_MONTHLY_STATISTICS_FAILURE";

export const GET_DAILY_CLICKS = "GET_DAILY_CLICKS";
export const GET_DAILY_CLICKS_SUCCESS = "GET_DAILY_CLICKS_SUCCESS";
export const GET_DAILY_CLICKS_FAILURE = "GET_DAILY_CLICKS_FAILURE";


export const GET_REJECTION_STATISTICS = "GET_REJECTION_STATISTICS";
export const GET_REJECTION_STATISTICS_SUCCESS =
  "GET_REJECTION_STATISTICS_SUCCESS";
export const GET_REJECTION_STATISTICS_FAILURE =
  "GET_REJECTION_STATISTICS_FAILURE";

export const GET_OS_STATISTICS = "GET_OS_STATISTICS";
export const GET_OS_STATISTICS_SUCCESS = "GET_OS_STATISTICS_SUCCESS";
export const GET_OS_STATISTICS_FAILURE = "GET_OS_STATISTICS_FAILURE";

export const SET_EXHAUSTED_CAP = "SET_EXHAUSTED_CAP";

export const SET_STATISTICS_LOADING = "SET_STATISTICS_LOADING";
export const SET_LOST_CLICK_LOADING = "SET_LOST_CLICK_LOADING";

export const GET_IPQS_HITS = "GET_IPQS_HITS";
export const GET_IPQS_HITS_SUCCESS = "GET_IPQS_HITS_SUCCESS";
export const GET_IPQS_HITS_FAILURE = "GET_IPQS_HITS_FAILURE";

export const GET_LOST_CLICK_REASONS = "GET_LOST_CLICK_REASONS";
export const GET_LOST_CLICK_REASONS_SUCCESS = "GET_LOST_CLICK_REASONS_SUCCESS";
export const GET_LOST_CLICK_REASONS_FAILURE = "GET_LOST_CLICK_REASONS_FAILURE";
