import { put, all, takeLatest } from "redux-saga/effects";
// import {toast} from "react-toastify"
import * as actionTypes from "./actionTypes";
import APIV2 from "../../Axios/AxiosV2";
import { toast } from "react-toastify";

function* getGeoTargetsSaga({ payload }) {
  try {
    yield put({ type: actionTypes.SET_GEO_TARGETS_LOADING });
    const { data } = yield APIV2.get("geo-targets/all", { params: payload });
    if (data.success) {
      yield put({
        type: actionTypes.GET_GEO_TARGETS_SUCCESS,
        payload: data.data,
      });
    } else {
      yield put({
        type: actionTypes.GET_GEO_TARGETS_FAILURE,
        errorMessage: data,
      });
    }
  } catch (error) {
    yield put({
      type: actionTypes.GET_GEO_TARGETS_FAILURE,
      errorMessage: "Unable to get geo targets",
    });
  }
}

function* getTargetTypeSaga() {
  try {
    yield put({ type: actionTypes.SET_GEO_TARGETS_LOADING });
    const { data } = yield APIV2.get("geo-targets/sub2/getTargetTypes");
    if (data.success) {
      yield put({
        type: actionTypes.GET_TARGET_TYPES_SUCCESS,
        payload: data.data,
      });
    } else {
      yield put({
        type: actionTypes.GET_TARGET_TYPES_FAILURE,
        errorMessage: data,
      });
    }
  } catch (error) {
    yield put({
      type: actionTypes.GET_TARGET_TYPES_FAILURE,
      errorMessage: "Unable to target types",
    });
  }
}

function* deleteGeoTargetsSaga({ id }) {
  try {
    yield put({ type: actionTypes.SET_GEO_TARGETS_LOADING });
    const { data } = yield APIV2.delete(`geo-targets/delete/${id}`);
    if (data.success) {
      toast.success("Delete success");
      yield put({ type: actionTypes.GET_GEO_TARGETS });
      yield put({
        type: actionTypes.DELETE_SELECTED_GEOTARGETS_SUCCESS,
        payload: data,
      });
    } else {
      toast.error("Unable to delete this!");
      yield put({
        type: actionTypes.DELETE_SELECTED_GEOTARGETS_FAILURE,
        errorMessage: "Unable to delete this!",
      });
    }
  } catch (error) {
    toast.error("Unable to delete this!");
    yield put({
      type: actionTypes.DELETE_SELECTED_GEOTARGETS_FAILURE,
      errorMessage: "Unable to delete this!",
    });
  }
}

function* searchGeoTargetsSaga({ payload }) {
  try {
    yield put({ type: actionTypes.SET_GEO_TARGETS_LOADING });
    const { data } = yield APIV2.get(
      `geo-targets/sub2/search/${payload.offerId}/${payload.conditionId}`,
      {
        params: {
          search: payload.value,
          target: payload.target_type,
        },
      }
    );
    if (data.success) {
      yield put({
        type: actionTypes.SEARCH_GEO_TARGET_SUCCESS,
        payload: data.data,
      });
    } else {
      yield put({
        type: actionTypes.SEARCH_GEO_TARGET_FAILURE,
      });
      toast.error("Unable to search!");
    }
  } catch (error) {
    toast.error("Unable to search!");
    yield put({
      type: actionTypes.SEARCH_GEO_TARGET_FAILURE,
      errorMessage: "Unable to search!",
    });
  }
}

function* deleteAllGeoTargetsSaga({ id }) {
  try {
    yield put({ type: actionTypes.SET_GEO_TARGETS_LOADING });
    const { data } = yield APIV2.post(`geo-targets/delete`, { ids: id });
    if (data.success) {
      toast.success("Delete success");
      yield put({ type: actionTypes.GET_GEO_TARGETS });
      yield put({
        type: actionTypes.DELETE_ALL_SELECTED_GEOTARGETS_SUCCESS,
        payload: data,
      });
    } else {
      toast.error("Unable to delete this!");
      yield put({
        type: actionTypes.DELETE_ALL_SELECTED_GEOTARGETS_FAILURE,
        errorMessage: "Unable to delete this!",
      });
    }
  } catch (error) {
    toast.error("Unable to delete this!");
    yield put({
      type: actionTypes.DELETE_ALL_SELECTED_GEOTARGETS_FAILURE,
      errorMessage: "Unable to delete this!",
    });
  }
}

function* importGeoTargetsCsvSaga({ payload }) {
  try {
    yield put({ type: actionTypes.SET_GEO_TARGETS_LOADING });
    const { data } = yield APIV2.post("geo-targets/import", payload);
    if (data.success) {
      yield put({ type: actionTypes.IMPORT_GEOTARGETS_CSV_SUCCESS });
      yield put({ type: actionTypes.GET_GEO_TARGETS });
    } else {
      yield put({
        type: actionTypes.IMPORT_GEOTARGETS_CSV_FAILURE,
        errorMessage: "Unable to Upload File!",
      });
    }
  } catch (error) {
    toast.error("Unable to Upload File!");
    yield put({
      type: actionTypes.IMPORT_GEOTARGETS_CSV_FAILURE,
      errorMessage: "Unable to upload File!",
    });
  }
}

function* createGeoTargetSaga({ payload }) {
  if (payload.canonical_name === undefined || null) {
    return toast.error("Fill All Field");
  } else {
    try {
      yield put({ type: actionTypes.SET_GEO_TARGETS_LOADING });
      const { data } = yield APIV2.post("/geo-targets/create", { ...payload });
      if (data.success) {
        toast.success("Geo Target Created");
        yield put({ type: actionTypes.CREATE_GEOTARGETS_SUCCESS });
        yield put({ type: actionTypes.GET_GEO_TARGETS });
      } else {
        yield put({
          type: actionTypes.CREATE_GEOTARGETS_FAILURE,
          errorMessage: "Unable Create Geo Target",
        });
      }
    } catch (error) {
      toast.error("Unable to Create Geo Target");
      yield put({
        type: actionTypes.CREATE_GEOTARGETS_FAILURE,
        errorMessage: "Unable to Create Geo Target",
      });
    }
  }
}

function* getCanoniclaNameSaga({ payload }) {
  try {
    const { data } = yield APIV2.get(`/geo-targets/search?search=${payload}`);
    if (data.success) {
      yield put({
        type: actionTypes.GET_CANONICAL_NAME_SUCCESS,
        payload: data.data,
      });
    } else {
      yield put({
        type: actionTypes.GET_CANONICAL_NAME_FAILURE,
        errorMessage: "Unable get this data!",
      });
    }
  } catch (error) {
    yield put({
      type: actionTypes.GET_CANONICAL_NAME_FAILURE,
      errorMessage: "Unable to get this data!",
    });
  }
}

function* toggleGeoTargetsSaga({ obj }) {
  try {
    yield put({ type: actionTypes.SET_GEO_TARGETS_LOADING });
    const { data } = yield APIV2.post(`/geo-targets/setStatus/${obj.id}`, obj);

    if (data.success) {
      toast.success("Status changed successfully.");
      yield put({ type: actionTypes.GET_GEO_TARGETS });
      yield put({
        type: actionTypes.TOGGLE_GEO_TARGETS_SUCCESS,
        payload: "Successful",
      });
    } else {
      toast.error("Unable to toggle.");
      yield put({
        type: actionTypes.TOGGLE_GEO_TARGETS_FAILURE,
        errorMessage: "Unable to toggle.",
      });
    }
  } catch (error) {
    toast.error("Unable to toggle.");
    yield put({
      type: actionTypes.TOGGLE_GEO_TARGETS_FAILURE,
      errorMessage: "Unable to toggle.",
    });
  }
}

function* getSingleGeoTargetSaga({ id }) {
  try {
    yield put({ type: actionTypes.SET_GEO_TARGETS_LOADING });
    const { data } = yield APIV2.get(`/geo-targets/getById/${id}`);
    if (data.success) {
      yield put({
        type: actionTypes.GET_SINGLE_GEO_TARGET_SUCCESS,
        payload: data.data,
      });
    } else {
      yield put({
        type: actionTypes.GET_SINGLE_GEO_TARGET_SUCCESS,
        errorMessage: "Unable to get this data!",
      });
    }
  } catch (error) {
    yield put({
      type: actionTypes.GET_SINGLE_GEO_TARGET_FAILURE,
      errorMessage: "Unable to get this data!",
    });
  }
}

function* editGeoTargetSaga({ payload }) {
  try {
    yield put({ type: actionTypes.SET_GEO_TARGETS_LOADING });
    const { data } = yield APIV2.post(`/geo-targets/edit/${payload.id}`, {
      ...payload.data,
    });
    if (data.success) {
      toast.success("Geo target edit successfully");
      yield put({ type: actionTypes.EDIT_GEO_TARGET_SUCCESS });
      yield put({ type: actionTypes.GET_GEO_TARGETS });
    } else {
      yield put({
        type: actionTypes.EDIT_GEO_TARGET_FAILURE,
        errorMessage: "Unable to edit this!",
      });
    }
  } catch (error) {
    yield put({
      type: actionTypes.EDIT_GEO_TARGET_FAILURE,
      errorMessage: "Unable to edit this!",
    });
  }
}

function* getCountryList() {
  try {
    yield put({ type: actionTypes.SET_GEO_TARGETS_LOADING });
    const { data } = yield APIV2.get("/geo-targets/countries");
    if (data.success) {
      yield put({
        type: actionTypes.GET_COUNTRIES_SUCCESS,
        payload: data.data,
      });
    } else {
      yield put({
        type: actionTypes.GET_COUNTRIES_FAILURE,
        errorMessage: "Unable to Fetch countries!",
      });
    }
  } catch (error) {
    yield put({
      type: actionTypes.GET_COUNTRIES_FAILURE,
      errorMessage: "Unable to Fetch countries!",
    });
  }
}

function* GeoTargetsSaga() {
  yield all([
    takeLatest(actionTypes.GET_GEO_TARGETS, getGeoTargetsSaga),
    takeLatest(actionTypes.DELETE_SELECTED_GEOTARGETS, deleteGeoTargetsSaga),
    takeLatest(actionTypes.IMPORT_GEOTARGETS_CSV, importGeoTargetsCsvSaga),
    takeLatest(actionTypes.TOGGLE_GEO_TARGETS, toggleGeoTargetsSaga),
    takeLatest(actionTypes.CREATE_GEOTARGETS, createGeoTargetSaga),
    takeLatest(actionTypes.GET_CANONICAL_NAME, getCanoniclaNameSaga),
    takeLatest(actionTypes.GET_CANONICAL_NAME, getCanoniclaNameSaga),
    takeLatest(
      actionTypes.DELETE_ALL_SELECTED_GEOTARGETS,
      deleteAllGeoTargetsSaga
    ),
    takeLatest(actionTypes.GET_SINGLE_GEO_TARGET, getSingleGeoTargetSaga),
    takeLatest(actionTypes.EDIT_GEO_TARGET, editGeoTargetSaga),
    takeLatest(actionTypes.SEARCH_GEO_TARGET, searchGeoTargetsSaga),
    takeLatest(actionTypes.GET_TARGET_TYPES, getTargetTypeSaga),
    takeLatest(actionTypes.GET_COUNTRIES, getCountryList),
  ]);
}

export default GeoTargetsSaga;
