import { put, all, takeLatest } from "redux-saga/effects";
import { toast } from "react-toastify";
import * as actionTypes from "./actionTypes";
import APIV2 from "../../Axios/AxiosV2";
import axios from "axios";

function* fetchAutomaticLogic({ params }) {
	try {
		yield put({ type: actionTypes.SET_LOGIC_LOADING });
		const { data } = yield APIV2.get("/automatic-logic/get-data", {
			params: params,
		});
		if (data.success) {
			yield put({
				type: actionTypes.FETCH_LOGIC_DATA_SUCCESS,
				payload: data.data,
			});
		} else {
			yield put({
				type: actionTypes.FETCH_LOGIC_DATA_FAILURE,
				errorMessage: data.message || "Unable to retrieve data.",
			});
			toast.error(data?.message || "Unable to retrieve data.");
		}
	} catch (error) {
		toast.error(error?.message || "Unable to retrieve data.");
		yield put({
			type: actionTypes.FETCH_LOGIC_DATA_FAILURE,
			errorMessage: "Unable to retrieve data.",
		});
	}
}

function* AddAutomaticLogic({ payload, successCallback }) {
	try {
		yield put({ type: actionTypes.SET_LOGIC_LOADING });
		const { data } = yield APIV2.post("/automatic-logic/save", { ...payload, created_by: localStorage.getItem("username") });

		if (data.success) {
			yield put({
				type: actionTypes.ADD_LOGIC_DATA_SUCCESS,
				payload: data.data,
			});
			if (successCallback) {
				successCallback();
			}
		} else {
			yield put({
				type: actionTypes.ADD_LOGIC_DATA_FAILURE,
				errorMessage: data.message || "Unable to add data.",
			});
			toast.error(data?.message || "Unable to add data.");
		}
	} catch (error) {
		toast.error(error?.message || "Unable to add data.");
		yield put({
			type: actionTypes.ADD_LOGIC_DATA_FAILURE,
			errorMessage: "Unable to retrieve data.",
		});
	}
}

function* deleteAutomaticLogic({ payload, successCallback }) {
	try {
		yield put({ type: actionTypes.SET_LOGIC_LOADING });
		const { data } = yield APIV2.delete("/automatic-logic/delete", {
			params: {
				id: payload,
			},
		});

		if (data.success) {
			yield put({
				type: actionTypes.DELETE_LOGIC_DATA_SUCCESS,
				payload: data.data,
			});
			if (successCallback) {
				successCallback();
			}
		} else {
			yield put({
				type: actionTypes.DELETE_LOGIC_DATA_FAILURE,
				errorMessage: data.message || "Unable to delete data.",
			});
			toast.error(data?.message || "Unable to delete data.");
		}
	} catch (error) {
		toast.error(error?.message || "Unable to delete data.");
		yield put({
			type: actionTypes.DELETE_LOGIC_DATA_FAILURE,
			errorMessage: "Unable to delete data.",
		});
	}
}

function* updateAutomaticLogic({ id, payload, successCallback }) {
	try {
		yield put({ type: actionTypes.SET_LOGIC_LOADING });
		const { data } = yield APIV2.put("/automatic-logic/update", payload, {
			params: {
				id: id,
			},
			
		});

		if (data.success) {
			yield put({
				type: actionTypes.UPDATE_LOGIC_DATA_SUCCESS,
				payload: data.data,
			});
			if (successCallback) {
				successCallback();
			}
		} else {
			yield put({
				type: actionTypes.UPDATE_LOGIC_DATA_FAILURE,
				errorMessage: data.message || "Unable to update data.",
			});
			toast.error(data?.message || "Unable to update data.");
		}
	} catch (error) {
		toast.error(error?.message || "Unable to update data.");
		yield put({
			type: actionTypes.UPDATE_LOGIC_DATA_FAILURE,
			errorMessage: "Unable to update data.",
		});
	}
}

function* AutomaticLogicSaga() {
	yield all([
		takeLatest(actionTypes.FETCH_LOGIC_DATA, fetchAutomaticLogic),
		takeLatest(actionTypes.ADD_LOGIC_DATA, AddAutomaticLogic),
		takeLatest(actionTypes.DELETE_LOGIC_DATA, deleteAutomaticLogic),
		takeLatest(actionTypes.UPDATE_LOGIC_DATA, updateAutomaticLogic),
	]);
}

export default AutomaticLogicSaga;
