export const GET_GLOBAL_CONDITIONS = "GET_GLOBAL_CONDITIONS";
export const GET_GLOBAL_CONDITIONS_SUCCESS = "GET_GLOBAL_CONDITIONS_SUCCESS";
export const GET_GLOBAL_CONDITIONS_FAILURE = "GET_GLOBAL_CONDITIONS_FAILURE";

export const UPDATE_GLOBAL_CONDITIONS = "UPDATE_GLOBAL_CONDITIONS";
export const UPDATE_GLOBAL_CONDITIONS_SUCCESS =
  "UPDATE_GLOBAL_CONDITIONS_SUCCESS";
export const UPDATE_GLOBAL_CONDITIONS_FAILURE =
  "UPDATE_GLOBAL_CONDITIONS_FAILURE";

export const SET_GLOBAL_CONDITIONS_LOADING = "SET_GLOBAL_CONDITIONS_LOADING";
