import * as actionTypes from "./actionTypes";

const initialState = {
  error: false,
  errorMessage: "",
  loading: false,
 automaticLogic:[],
 currentPage:null,
 dataPerPage:null,
 totalData:null,
 totalPages:null
};

const AutomaticLogicData = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_LOGIC_LOADING:
      return {
        ...state,
        loading: true,
        error: false,
        errorMessage: "",
      };
      case actionTypes.FETCH_LOGIC_DATA_SUCCESS:
        return {
          ...state,
          loading: false,
          error: false,
          errorMessage: "",
          automaticLogic:action.payload.list,
          currentPage:action.payload.current_page,
          dataPerPage:action.payload.dataPerPage,
          totalData:action.payload.totalData,
          totalPages:action.payload.totalPages
        };

        case actionTypes.FETCH_LOGIC_DATA_FAILURE:
          return {
            ...state,
            loading: false,
            error: true,
            errorMessage: action.errorMessage,
          };
          case actionTypes.ADD_LOGIC_DATA_SUCCESS:
            return {
              ...state,
              loading: false,
              error: false,
            };

          case actionTypes.ADD_LOGIC_DATA_FAILURE:
            return {
              ...state,
              loading: false,
              error: true,
              errorMessage: action.errorMessage,
            };
            case actionTypes.DELETE_LOGIC_DATA_SUCCESS:
              return {
                ...state,
                loading: false,
                error: false,
              };
  
            case actionTypes.DELETE_LOGIC_DATA_FAILURE:
              return {
                ...state,
                loading: false,
                error: true,
                errorMessage: action.errorMessage,
              };
              case actionTypes.UPDATE_LOGIC_DATA_SUCCESS:
                return {
                  ...state,
                  loading: false,
                  error: false,
                };
    
              case actionTypes.UPDATE_LOGIC_DATA_FAILURE:
                return {
                  ...state,
                  loading: false,
                  error: true,
                  errorMessage: action.errorMessage,
                };
  

    default:
      return state;
  }
};

export default AutomaticLogicData;
