import * as actionTypes from "./actionTypes";

const initialState = {
  error: false,
  errorMessage: "",
  loading: false,
  fetchData: [],
  getUsers: [],
  _id: "",
  displayName: "",
  email: "",
  uid: "",
  type: "",
  employee_type: "",
  permissions: {},
  userDetailes:{},
};

const UserData = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_USERS_LOADING:
      return {
        ...state,
        loading: true,
        error: false,
        errorMessage: "",
      };

    case actionTypes.FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        fetchData: action.payload,
      };
    case actionTypes.FETCH_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.errorMessage,
      };

    case actionTypes.GET_USERS_SUCCESS:
      return {
        ...state,
        loading: false,
        getUsers: action.payload,
      };

    case actionTypes.GET_USERS_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.errorMessage,
      };

    case actionTypes.SHOPPING_OFFER_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.SHOPPING_OFFER_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.errorMessage,
      };

    case actionTypes.DELETE_SHOPPING_USER_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.DELETE_SHOPPING_USER_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.errorMessage,
      };

    case actionTypes.SIGNUP_USER_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case actionTypes.SIGNUP_USER_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.errorMessage,
      };
    case actionTypes.GET_ACCOUNT_INFO_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case actionTypes.GET_ACCOUNT_INFO_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.errorMessage,
      };
    case actionTypes.GET_OOB_CONFIRMATION_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case actionTypes.GET_OOB_CONFIRMATION_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.errorMessage,
      };
    case actionTypes.VERIFY_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        _id: action.payload._id,
        displayName:action.payload.displayName,
        email:action.payload.email,
        uid:action.payload.uid,
        type:action.payload.type,
        employee_type:action.payload.employee_type,
        permissions:action.payload.permissions.reduce((a, v) => ({ ...a, [v]: true}), {}) ,
      };

    case actionTypes.VERIFY_USER_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.errorMessage,
      };

    case actionTypes.GET_USER_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        userDetailes: action.payload,
      };

    case actionTypes.GET_USER_BY_ID_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.errorMessage,
      };

    default:
      return state;
  }
};

export default UserData;
