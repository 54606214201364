export const GET_AUDIT_BY_OFFER_ID = "GET_AUDIT_BY_OFFER_ID";
export const GET_AUDIT_BY_OFFER_ID_SUCCESS = "GET_AUDIT_BY_OFFER_ID_SUCCESS";
export const GET_AUDIT_BY_OFFER_ID_FAILURE = "GET_AUDIT_BY_OFFER_ID_FAILURE";

export const GET_AUDIT_BY_ID = "GET_AUDIT_BY_ID";
export const GET_AUDIT_BY_ID_SUCCESS = "GET_AUDIT_BY_ID_SUCCESS";
export const GET_AUDIT_BY_ID_FAILURE = "GET_AUDIT_BY_ID_FAILURE";

export const GET_AUDIT_LIST = "GET_AUDIT_LIST";
export const GET_AUDIT_LIST_SUCCESS = "GET_AUDIT_LIST_SUCCESS";
export const GET_AUDIT_LIST_FAILURE = "GET_AUDIT_LIST_FAILURE";

export const GET_OFFER_AUDIT_LIST = "GET_OFFER_AUDIT_LIST";
export const GET_OFFER_AUDIT_LIST_SUCCESS = "GET_OFFER_AUDIT_LIST_SUCCESS";
export const GET_OFFER_AUDIT_LIST_FAILURE = "GET_OFFER_AUDIT_LIST_FAILURE";

export const CLEAR_SELECTED_AUDIT = "CLEAR_SELECTED_AUDIT"



export const SET_AUDIT_LOADING = "SET_AUDIT_LOADING";
export const RESET_AUDIT= "RESET_AUDIT";
