import * as actionTypes from "./actionTypes";

const initialState = {
  error: false,
  errorMessage: "",
  loading: false,
  getRequestedData: [],
  getExportLink: {},
  getPresets: [],
};

const ExportData = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_EXPORT_DATA_LOADING:
      return {
        ...state,
        loading: true,
        error: false,
        errorMessage: "",
      };
    case actionTypes.GET_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
        getRequestedData: action.payload,
      };
    case actionTypes.GET_REQUEST_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.errorMessage,
      };

    case actionTypes.EXPORT_LINK_SUCCESS:
      return {
        ...state,
        loading: false,
        getExportLink: action.payload,
        getRequestedData: action.payload,
      };
    case actionTypes.EXPORT_LINK_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.errorMessage,
      };

    case actionTypes.GET_PRESETS_SUCCESS:
      return {
        ...state,
        loading: false,
        getPresets: action.payload,
      };
    case actionTypes.GET_PRESETS_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.errorMessage,
      };

    case actionTypes.ADD_PRESETS_SUCCESS:
      return {
        ...state,
        loading: false,
        getPresets: action.payload,
      };
    case actionTypes.ADD_PRESETS_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.errorMessage,
      };

    case actionTypes.UPDATE_PRESETS_SUCCESS:
      return {
        ...state,
        loading: false,
        getPresets: action.payload,
      };
    case actionTypes.UPDATE_PRESETS_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.errorMessage,
      };

    case actionTypes.DELETE_PRESETS_SUCCESS:
      return {
        ...state,
        loading: false,
        getPresets: action.payload,
      };
    case actionTypes.DELETE_PRESETS_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.errorMessage,
      };
    default:
      return state;
  }
};

export default ExportData;
