import { put, all, takeLatest } from "redux-saga/effects";
import { toast } from "react-toastify";
import * as actionTypes from "./actionTypes";
import APIV2 from "../../Axios/AxiosV2";
import API from "../../Axios/Axios"



function* getRequestedFilesSaga({category}) {
  try {
    yield put({ type: actionTypes.SET_EXPORT_DATA_LOADING });
    const getData = yield API.post("/getRequestedFiles", {},{ params: { category}});

    if (getData) {
      yield put({
        type: actionTypes.GET_REQUEST_SUCCESS,
        payload: getData.data.data,
      });
    } else {
      yield put({
        type: actionTypes.GET_REQUEST_FAILURE,
        errorMessage: getData.data.message,
      });
    }
  } catch (error) {
    yield put({
      type: actionTypes.GET_REQUEST_FAILURE,
      errorMessage: "Unable to export.",
    });
  }
}

function* exportLinkSaga({ obj }) {
  try {
    yield put({ type: actionTypes.SET_EXPORT_DATA_LOADING });
    const data = yield API.post("/exportLink", obj);
    const getData = yield API.post("/getRequestedFiles", {});

    if (getData) {
      toast.success("exported data successfully");
      yield put({
        type: actionTypes.GET_REQUEST_SUCCESS,
        payload: getData.data.data,
        // payload: presetdata.data.data,
      });
    } else {
      // toast.error(data.data.message);
      yield put({
        type: actionTypes.EXPORT_LINK_FAILURE,
        errorMessage: data.data.message,
      });
    }
  } catch (error) {
    toast.error("Unable to export.");
    yield put({
      type: actionTypes.EXPORT_LINK_FAILURE,
      errorMessage: "Unable to export.",
    });
  }
}

function* getPresetsSaga({ category }) {
  try {
    // yield put({ type: actionTypes.SET_EXPORT_DATA_LOADING });
    const data = yield API.get("/presets", { params: { category: category } });
    if (data) {
      yield put({
        type: actionTypes.GET_PRESETS_SUCCESS,
        payload: data.data.data,
      });
    } else {
      // toast.error(data.data.message);
      yield put({
        type: actionTypes.GET_PRESETS_FAILURE,
        errorMessage: data.data.message,
      });
    }
  } catch (error) {
    yield put({
      type: actionTypes.GET_PRESETS_FAILURE,
      errorMessage: "Unable to export.",
    });
  }
}
function* addPresetsSaga(obj) {
  try {
    // yield put({ type: actionTypes.SET_EXPORT_DATA_LOADING });
    const addPresets = yield API.post("/preset/add", obj.obj);
    const data = yield API.get("/presets");

    if (data) {
      toast.success("presets added successfully");
      yield put({
        type: actionTypes.ADD_PRESETS_SUCCESS,
        payload: data.data.data,
      });
    } else {
      // toast.error(data.data.message);
      yield put({
        type: actionTypes.ADD_PRESETS_FAILURE,
        errorMessage: addPresets.data.message,
      });
    }
  } catch (error) {
    yield put({
      type: actionTypes.ADD_PRESETS_FAILURE,
      errorMessage: "Unable to add presets.",
    });
  }
}

function* updatePresetsSaga(update) {
  try {
    const updatePresets = yield API.post(
      "/preset/update",
      update.update,
      update.id
    );
    const data = yield API.get("/presets");

    if (data) {
      toast.success("presets update successfully");
      yield put({
        type: actionTypes.UPDATE_PRESETS_SUCCESS,
        payload: data.data.data,
      });
    } else {
      yield put({
        type: actionTypes.UPDATE_PRESETS_FAILURE,
        errorMessage: updatePresets.data.message,
      });
    }
  } catch (error) {
    yield put({
      type: actionTypes.UPDATE_PRESETS_FAILURE,
      errorMessage: "Unable to update presets.",
    });
  }
}

function* deletePresetsSaga({ id }) {
  try {
    const deletePresets = yield API.post("/preset/delete", id);
    const data = yield API.get("/presets");

    if (data) {
      toast.success("deleted presets successfully");
      yield put({
        type: actionTypes.DELETE_PRESETS_SUCCESS,
        payload: data.data.data,
      });
    } else {
      // toast.error(data.data.message);
      yield put({
        type: actionTypes.DELETE_PRESETS_FAILURE,
        errorMessage: deletePresets.data.message,
      });
    }
  } catch (error) {
    yield put({
      type: actionTypes.DELETE_PRESETS_FAILURE,
      errorMessage: "Unable to update presets.",
    });
  }
}

function* ExportDataSaga() {
  yield all([takeLatest(actionTypes.GET_REQUEST, getRequestedFilesSaga)]);
  yield all([takeLatest(actionTypes.EXPORT_LINK, exportLinkSaga)]);
  yield all([takeLatest(actionTypes.GET_PRESETS, getPresetsSaga)]);
  yield all([takeLatest(actionTypes.ADD_PRESETS, addPresetsSaga)]);
  yield all([takeLatest(actionTypes.UPDATE_PRESETS, updatePresetsSaga)]);
  yield all([takeLatest(actionTypes.DELETE_PRESETS, deletePresetsSaga)]);
}

export default ExportDataSaga;
