export const GET_OFFERS = "GET_OFFERS";
export const GET_OFFERS_SUCCESS = "GET_OFFERS_SUCCESS";
export const GET_OFFERS_FAILURE = "GET_OFFERS_FAILURE";

export const GET_OFFERS_STATISTICS = "GET_OFFERS_STATISTICS";
export const GET_OFFERS_STATISTICS_SUCCESS = "GET_OFFERS_STATISTICS_SUCCESS";
export const GET_OFFERS_STATISTICS_FAILURE = "GET_OFFERS_STATISTICS_FAILURE";

export const GET_OFFER_DETAILS = "GET_OFFER_DETAILS";
export const GET_OFFER_DETAILS_SUCCESS = "GET_OFFER_DETAILS_SUCCESS";
export const GET_OFFER_DETAILS_FAILURE = "GET_OFFER_DETAILS_FAILURE";

export const GET_AUDITOR_STATUS = "GET_AUDITOR_STATUS";
export const GET_AUDITOR_STATUS_SUCCESS = "GET_AUDITOR_STATUS_SUCCESS";
export const GET_AUDITOR_STATUS_FAILURE = "GET_AUDITOR_STATUS_FAILURE";

export const ADD_OFFER = "ADD_OFFER";
export const ADD_OFFER_SUCCESS = "ADD_OFFER_SUCCESS";
export const ADD_OFFER_FAILURE = "ADD_OFFER_FAILURE";

export const EDIT_OFFER = "EDIT_OFFER";
export const EDIT_OFFER_SUCCESS = "EDIT_OFFER_SUCCESS";
export const EDIT_OFFER_FAILURE = "EDIT_OFFER_FAILURE";

export const CLONE_OFFER = "CLONE_OFFER";
export const CLONE_OFFER_SUCCESS = "CLONE_OFFER_SUCCESS";
export const CLONE_OFFER_FAILURE = "CLONE_OFFER_FAILURE";

export const DELETE_OFFER = "DELETE_OFFER";
export const DELETE_OFFER_SUCCESS = "DELETE_OFFER_SUCCESS";
export const DELETE_OFFER_FAILURE = "DELETE_OFFER_FAILURE";

export const PAUSE_OFFER = "PAUSE_OFFER";
export const PAUSE_OFFER_SUCCESS = "PAUSE_OFFER_SUCCESS";
export const PAUSE_OFFER_FAILURE = "PAUSE_OFFER_FAILURE";

export const RESUME_OFFER = "RESUME_OFFER";
export const RESUME_OFFER_SUCCESS = "RESUME_OFFER_SUCCESS";
export const RESUME_OFFER_FAILURE = "RESUME_OFFER_FAILURE";

export const TOGGLE_OFFER = "TOGGLE_OFFER";
export const TOGGLE_OFFER_SUCCESS = "TOGGLE_OFFER_SUCCESS";
export const TOGGLE_OFFER_FAILURE = "TOGGLE_OFFER_FAILURE";

export const DELETE_SELECTED_OFFERS = "DELETE_SELECTED_OFFERS";
export const DELETE_SELECTED_OFFERS_SUCCESS = "DELETE_SELECTED_OFFERS_SUCCESS";
export const DELETE_SELECTED_OFFERS_FAILURE = "DELETE_SELECTED_OFFERS_FAILURE";

export const SET_OFFERS_LOADING = "SET_OFFERS_LOADING";
export const CLEAR_OFFER_DETAILS = "CLEAR_OFFER_DETAILS";

export const ASSIGN_OFFER_ACTION = "ASSIGN_OFFER_ACTION"
export const ASSIGN_OFFER_SUCCESS = "ASSIGN_OFFER_SUCCESS";
export const ASSIGN_OFFER_FAILURE = "ASSIGN_OFFER_FAILURE";

export const GET_REJECTION = "GET_REJECTION";
export const GET_REJECTION_SUCCESS = "GET_REJECTION_SUCCESS";
export const GET_REJECTION_FAILURE = "GET_REJECTION_FAILURE";

export const SET_REJECTION_LOADING = "SET_REJECTION_LOADING";

export const CLEAR_REJECTION_DATA = "CLEAR_REJECTION_DATA";

