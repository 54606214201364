import * as actionTypes from "./actionTypes";

const initialState = {
  error: false,
  errorMessage: "",
  message: "",
  loading: false,
  currentPage: 1,
  dataPerPage: 20,
  totalData: 192,
  totalPages: 1,
  sub2: [],
};

const Sub2Reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_SUB2_LOADING:
      return {
        ...state,
        loading: true,
        error: false,
        errorMessage: "",
      };
      case actionTypes.GET_SUB2_SUCCESS:
        return {
          ...initialState,
          sub2: action.payload.data,
          currentPageSub2: action.payload.currentPage,
          dataPerPageSub2: action.payload.dataPerPage,
          totalDataSub2: action.payload.totalData,
          totalPagesSub2: action.payload.totalPages,
        };
      case actionTypes.GET_SUB2_FAILURE:
        return {
          ...initialState,
          error: true,
          errorMessage: action.errorMessage,
        };
        case actionTypes.DELETE_SELECTED_SUB2_SUCCESS:
          return {
            ...state,
            loading: false,
            message: action.payload,
          }
          case actionTypes.DELETE_SELECTED_SUB2_FAILURE: 
          return {
            ...state,
            loading: false,
            error: true,
            errorMessage: action.errorMessage,
          }
    default:
      return state;
  }
};

export default Sub2Reducer;
