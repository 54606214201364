import * as actionTypes from "./actionTypes";

const initialState = {
  error: false,
  errorMessage: "",
  message: "",
  loading: false,
  statistics: [],
  originList: [],
  exhaustedList: [],
  dailyClicks: [],
  IPQSHitsData: [],
  lostClickReasons: [],
  lostClickLoader: false,
};

const Statistics = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_STATISTICS_LOADING:
      return {
        ...state,
        loading: true,
        error: false,
      };
      case actionTypes.SET_LOST_CLICK_LOADING:
        return {
          ...state,
          lostClickLoader: true,
          error: false,
        };
    case actionTypes.SET_EXHAUSTED_CAP:
      state.exhaustedList[action.payload.offerIndex].condition[
        action.payload.conditionindex
      ].weightage_urls[action.payload.URlIndex].cap = action.payload.value;
      return {
        ...state,
        exhaustedList: [...state.exhaustedList],
      };
    case actionTypes.GET_STATISTICS_SUCCESS:
      return {
        ...state,
        loading: false,
        statistics: Object.values(action.payload).reverse(),
      };
    case actionTypes.GET_DAILY_STATISTICS_SUCCESS:
      return {
        ...state,
        loading: false,
        statistics: {
          ...action.payload,
          arrayToReverse: action.payload.toReturn.reverse(),
        },
      };
    case actionTypes.GET_WEEKLY_STATISTICS_SUCCESS:
      return {
        ...state,
        loading: false,
        statistics: {
          ...action.payload,
          arrayToReverse: action.payload.toReturn.reverse(),
        },
      };
    case actionTypes.GET_MONTHLY_STATISTICS_SUCCESS:
      return {
        ...state,
        loading: false,
        statistics: {
          ...action.payload,
          arrayToReverse: action.payload.toReturn.reverse(),
        },
      };
    case actionTypes.GET_COUNTRY_STATISTICS_SUCCESS:
      return {
        ...state,
        loading: false,
        statistics: {
          ...action.payload,
          arrayToReverse: action.payload.toReturn.slice().reverse(),
        },
      };
    case actionTypes.GET_REJECTION_STATISTICS_SUCCESS:
      return {
        ...state,
        loading: false,
        statistics: {
          ...action.payload,
          arrayToReverse: action.payload.toReturn.slice().reverse(),
        },
      };
    case actionTypes.GET_DEVICE_STATISTICS_SUCCESS:
      return {
        ...state,
        loading: false,
        statistics: {
          ...action.payload,
          arrayToReverse: action.payload.toReturn.slice().reverse(),
        },
      };
    case actionTypes.GET_OS_STATISTICS_SUCCESS:
      return {
        ...state,
        loading: false,
        statistics: {
          ...action.payload,
          arrayToReverse: action.payload.toReturn.slice().reverse(),
        },
      };
    case actionTypes.GET_REJECTION_STATISTICS_FAILURE:
    case actionTypes.GET_OS_STATISTICS_FAILURE:
    case actionTypes.GET_DEVICE_STATISTICS_FAILURE:
    case actionTypes.GET_COUNTRY_STATISTICS_FAILURE:
    case actionTypes.GET_DAILY_STATISTICS_FAILURE:
    case actionTypes.GET_WEEKLY_STATISTICS_FAILURE:
    case actionTypes.GET_MONTHLY_STATISTICS_FAILURE:
    case actionTypes.GET_STATISTICS_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.errorMessage,
      };
    case actionTypes.GET_ORIGIN_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        originList: action.payload,
      };
    case actionTypes.GET_ORIGIN_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.errorMessage,
      };
    case actionTypes.GET_EXHAUSTED_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        exhaustedList: Object.values(action.payload),
      };
    case actionTypes.GET_EXHAUSTED_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.errorMessage,
      };
    case actionTypes.EDIT_EXHAUSTED_CAP_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.EDIT_EXHAUSTED_CAP_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };

    case actionTypes.GET_DAILY_CLICKS_SUCCESS:
      return {
        ...state,
        loading: false,
        dailyClicks: action.payload,
      };
    case actionTypes.GET_IPQS_HITS_SUCCESS:
      return {
        ...state,
        loading: false,
        IPQSHitsData: action.payload,
      };
    case actionTypes.GET_IPQS_HITS_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.errorMessage,
      };
    case actionTypes.GET_DAILY_CLICKS_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case actionTypes.GET_LOST_CLICK_REASONS_SUCCESS:
      return {
        ...state,
        loading: false,
        lostClickReasons: action.payload,
      };
    case actionTypes.GET_LOST_CLICK_REASONS_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.errorMessage,
      };

    default:
      return state;
  }
};

export default Statistics;
