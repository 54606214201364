import { all } from "redux-saga/effects";

import AuthSaga from "./Auth/saga";
import DashboardSaga from "./Dashboard/saga";
import GlobalConditionsSaga from "./GlobalConditions/saga";
import OffersSaga from "./Offers/saga";
import ExportDataSaga from "./ExportData/saga";
import UserDataSaga from "./Users/saga";
import AdvertiserDataSaga from "./Advertisers/saga";
import StatisticsSaga from "./Statistics/saga";
import AuditSaga from "./Audit/saga";
import RolesSaga from "./Roles/saga";
import FileManagerSaga from "./FileManager/saga";
import GeoTargetsSaga from "./GeoTargets/saga";
import Sub2Saga from "./Sub2/saga";
import ContainerTagSaga from "./ContainerTag/saga";
import AutomaticLogicSaga from "./AutomaticLogic/saga"

export default function* rootSaga() {
  yield all([
    AuthSaga(),
    DashboardSaga(),
    OffersSaga(),
    GlobalConditionsSaga(),
    ExportDataSaga(),
    UserDataSaga(),
    AdvertiserDataSaga(),
    StatisticsSaga(),
    AuditSaga(),
    RolesSaga(),
    FileManagerSaga(),
    GeoTargetsSaga(),
    Sub2Saga(),
    ContainerTagSaga(),
    AutomaticLogicSaga()
  ]);
}
