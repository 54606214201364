export const FETCH_ADVERTISERS = "FETCH_ADVERTISERS";
export const FETCH_ADVERTISERS_SUCCESS = "FETCH_ADVERTISERS_SUCCESS";
export const FETCH_ADVERTISERS_FAILURE = "FETCH_ADVERTISERS_FAILURE";

export const GET_ADVERTISERS = "GET_ADVERTISERS";
export const GET_ADVERTISERS_SUCCESS = "GET_ADVERTISERS_SUCCESS";
export const GET_ADVERTISERS_FAILURE = "GET_ADVERTISERS_FAILURE";

export const GET_ADVERTISER_BY_ID = "GET_ADVERTISER_BY_ID";
export const GET_ADVERTISER_BY_ID_SUCCESS = "GET_ADVERTISER_BY_ID_SUCCESS";
export const GET_ADVERTISER_BY_ID_FAILURE = "GET_ADVERTISER_BY_ID_FAILURE";

export const EDIT_ADVERTISER = "EDIT_ADVERTISER";
export const EDIT_ADVERTISER_SUCCESS = "EDIT_ADVERTISER_SUCCESS";
export const EDIT_ADVERTISER_FAILURE = "EDIT_ADVERTISER_FAILURE";

export const SHOPPING_ADVERTISERS_OFFER = "SHOPPING_ADVERTISERS_OFFER";
export const SHOPPING_OFFER_ADVERTISERS_SUCCESS = "SHOPPING_OFFER_ADVERTISERS_SUCCESS";
export const SHOPPING_OFFER_ADVERTISERS_FAILURE = "SHOPPING_OFFER_ADVERTISERS_FAILURE";

export const DELETE_SHOPPING_ADVERTISER = "DELETE_SHOPPING_ADVERTISER";
export const DELETE_SHOPPING_ADVERTISER_SUCCESS = "DELETE_SHOPPING_ADVERTISER_SUCCESS";
export const DELETE_SHOPPING_ADVERTISER_FAILURE = "DELETE_SHOPPING_ADVERTISER_FAILURE";

// DHappa action types

export const SIGNUP_ADVERTISER = "SIGNUP_ADVERTISER";
export const SIGNUP_ADVERTISER_SUCCESS = "SIGNUP_ADVERTISER_SUCCESS";
export const SIGNUP_ADVERTISER_FAILURE = "SIGNUP_ADVERTISER_FAILURE";

export const VERIFY_ADVERTISER = "VERIFY_ADVERTISER";
export const VERIFY_ADVERTISER_SUCCESS = "VERIFY_ADVERTISER_SUCCESS";
export const VERIFY_ADVERTISER_FAILURE = "VERIFY_ADVERTISER_FAILURE";

export const GET_ACCOUNT_INFO = "GET_ACCOUNT_INFO";
export const GET_ACCOUNT_INFO_SUCCESS = "GET_ACCOUNT_INFO_SUCCESS";
export const GET_ACCOUNT_INFO_FAILURE = "GET_ACCOUNT_INFO_FAILURE";

export const GET_OOB_CONFIRMATION = "GET_OOB_CONFIRMATION";
export const GET_OOB_CONFIRMATION_SUCCESS = "GET_OOB_CONFIRMATION_SUCCESS";
export const GET_OOB_CONFIRMATION_FAILURE = "GET_OOB_CONFIRMATION_FAILURE";

export const SET_ADVERTISERS_LOADING = "SET_ADVERTISERS_LOADING";

export const SET_ADVERTISERS_REDIRECT_TRUE = "SET_ADVERTISERS_REDIRECT_TRUE";
export const SET_ADVERTISERS_REDIRECT_FALSE = "SET_ADVERTISERS_REDIRECT_FALSE";
export const SET_ADVERTISERS_REDIRECT_FALSE_SUCCESS = "SET_ADVERTISERS_REDIRECT_FALSE_SUCCESS";

