import { put, all, takeLatest } from "redux-saga/effects";
import { toast } from "react-toastify";
import * as actionTypes from "./actionTypes";
import APIV2 from "../../Axios/AxiosV2";

function* getAuditByOfferIdSaga({ offerId }) {
  try {
    yield put({ type: actionTypes.SET_AUDIT_LOADING });
    const { data } = yield APIV2.get(`/audit/get/${offerId}`);

    if (data.success) {
      yield put({
        type: actionTypes.GET_AUDIT_BY_OFFER_ID_SUCCESS,
        payload: data.data,
      });
    } else {
      yield put({
        type: actionTypes.GET_AUDIT_BY_OFFER_ID_FAILURE,
        errorMessage: "Unable to load Audit.",
      });
    }
  } catch (error) {
    toast.error("Unable to load Audit.");
    yield put({
      type: actionTypes.GET_AUDIT_BY_OFFER_ID_FAILURE,
      errorMessage: "Unable to load Audit.",
    });
  }
}
function* getAuditByIdSaga({ id }) {
  try {
    yield put({ type: actionTypes.SET_AUDIT_LOADING });
    const { data } = yield APIV2.get(`/audit/getbyId/${id}`);

    if (data.success) {
      yield put({
        type: actionTypes.GET_AUDIT_BY_ID_SUCCESS,
        payload: data.data,
      });
    } else {
      yield put({
        type: actionTypes.GET_AUDIT_BY_ID_FAILURE,
        errorMessage: "Unable to load Audit.",
      });
    }
  } catch (error) {
    toast.error("Unable to load Audit.");
    yield put({
      type: actionTypes.GET_AUDIT_BY_ID_FAILURE,
      errorMessage: "Unable to load Audit.",
    });
  }
}

function* getAuditListSaga({ params }) {
  try {
    yield put({ type: actionTypes.SET_AUDIT_LOADING });
    const { data } = yield APIV2.get(`/audit/all`, { params: { ...params } });

    if (data.success) {
      yield put({
        type: actionTypes.GET_AUDIT_LIST_SUCCESS,
        payload: data.data,
      });
    } else {
      yield put({
        type: actionTypes.GET_AUDIT_LIST_FAILURE,
        errorMessage: "Unable to load Audit List.",
      });
    }
  } catch (error) {
    toast.error("Unable to load Audit List.");
    yield put({
      type: actionTypes.GET_AUDIT_LIST_FAILURE,
      errorMessage: "Unable to load Audit List.",
    });
  }
}
function* getOfferAuditListSaga({ offerId }) {
  try {
    yield put({ type: actionTypes.SET_AUDIT_LOADING });
    const { data } = yield APIV2.get(`/audit/getList/${offerId}`);

    if (data.success) {
      yield put({
        type: actionTypes.GET_OFFER_AUDIT_LIST_SUCCESS,
        payload: data.data,
      });
    } else {
      yield put({
        type: actionTypes.GET_OFFER_AUDIT_LIST_FAILURE,
        errorMessage: "Unable to load Audit List.",
      });
    }
  } catch (error) {
    toast.error("Unable to load Audit List.");
    yield put({
      type: actionTypes.GET_OFFER_AUDIT_LIST_FAILURE,
      errorMessage: "Unable to load Audit List.",
    });
  }
}

function* AuditSaga() {
  yield all([
    takeLatest(actionTypes.GET_AUDIT_BY_OFFER_ID, getAuditByOfferIdSaga),
    takeLatest(actionTypes.GET_AUDIT_BY_ID, getAuditByIdSaga),
    takeLatest(actionTypes.GET_AUDIT_LIST, getAuditListSaga),
    takeLatest(actionTypes.GET_OFFER_AUDIT_LIST, getOfferAuditListSaga),
  
  ]);
}

export default AuditSaga;