import * as actionTypes from "./actionTypes";

const initialState = {
  error: false,
  errorMessage: "",
  offers: [],
  offerLoading: false,
  rejectionLoading: false,
  offerDetails: {},
  weightageData: {},
  statistics: {},
  currentPage: 1,
  dataPerPage: 20,
  totalData: 192,
  totalPages: 1,
  rejectionData: [],
};

const Offers = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_OFFERS_LOADING:
      return {
        ...state,
        offerLoading: true,
        error: false,
        errorMessage: "",
      };
    case actionTypes.SET_REJECTION_LOADING:
      return{
        ...state,
        rejectionLoading: true,
        error: false,
        errorMessage: "",
      }
    case actionTypes.GET_OFFERS_SUCCESS:
      return {
        ...state,
        offerLoading: false,
        offers: action.payload.list,
        currentPage: action.payload.currentPage,
        dataPerPage: action.payload.dataPerPage,
        totalData: action.payload.totalData,
        totalPages: action.payload.totalPages,
      };
    case actionTypes.GET_OFFERS_FAILURE:
      return {
        ...state,
        offerLoading: false,
        error: true,
        errorMessage: action.errorMessage,
      };
    case actionTypes.GET_OFFERS_STATISTICS_SUCCESS:
      return {
        ...state,
        offerLoading: false,
        statistics: action.payload,
      };
    case actionTypes.GET_OFFERS_STATISTICS_FAILURE:
      return {
        ...state,
        offerLoading: false,
      };
    case actionTypes.GET_AUDITOR_STATUS_SUCCESS:
      return {
        ...state,
        offerLoading: false,
        weightageData: action.payload,
      };
    case actionTypes.GET_AUDITOR_STATUS_FAILURE:
      return {
        ...state,
        offerLoading: false,
        error: true,
        errorMessage: action.errorMessage,
      };
    case actionTypes.GET_OFFER_DETAILS_SUCCESS:
      return {
        ...state,
        offerLoading: false,
        offerDetails: action.payload.link,
        weightageData: action.payload.weightageData,
        statistics: action.payload.urls,
      };
    case actionTypes.ADD_OFFER_SUCCESS:
    case actionTypes.EDIT_OFFER_SUCCESS:
    case actionTypes.ASSIGN_OFFER_SUCCESS:
      return {
        ...state,
        offerLoading: false,
        offerDetails: action.payload,
      };
    case actionTypes.CLONE_OFFER_SUCCESS:
    case actionTypes.DELETE_OFFER_SUCCESS:
    case actionTypes.DELETE_SELECTED_OFFERS_SUCCESS:
    case actionTypes.PAUSE_OFFER_SUCCESS:
    case actionTypes.RESUME_OFFER_SUCCESS:
    case actionTypes.TOGGLE_OFFER_SUCCESS:
      return {
        ...state,
        offerLoading: false,
        message: action.payload,
      };
    case actionTypes.GET_OFFER_DETAILS_FAILURE:
    case actionTypes.CLONE_OFFER_FAILURE:
    case actionTypes.DELETE_SELECTED_OFFERS_FAILURE:
    case actionTypes.PAUSE_OFFER_FAILURE:
    case actionTypes.RESUME_OFFER_FAILURE:
    case actionTypes.DELETE_OFFER_FAILURE:
    case actionTypes.TOGGLE_OFFER_FAILURE:
    case actionTypes.ADD_OFFER_FAILURE:
    case actionTypes.EDIT_OFFER_FAILURE:
    case actionTypes.GET_REJECTION_FAILURE:
      return {
        ...state,
        offerLoading: false,
        error: true,
        rejectionLoading: false,
        errorMessage: action.errorMessage,
      };
    case actionTypes.CLEAR_OFFER_DETAILS:
      return {
        ...state,
        offerDetails: {},
      };
    case actionTypes.GET_REJECTION_SUCCESS:
      return {
        ...state,
        rejectionLoading: false,
        error: false,
        rejectionData: action.payload,
      };
    case actionTypes.CLEAR_REJECTION_DATA:
      return {
        ...state,
        offerLoading: false,
        error: false,
        rejectionData: [],
      }
    default:
      return state;
  }
};

export default Offers;
