export const SET_CONTAINER_TAG_LOADING = "SET_CONTAINER_TAG_LOADING";

export const ADD_CONTAINER_TAG = "ADD_CONTAINER_TAG";
export const ADD_CONTAINER_TAG_SUCCESS = "ADD_CONTAINER_TAG_SUCCESS";
export const ADD_CONTAINER_TAG_FAILURE = "ADD_CONTAINER_TAG_FAILURE";

export const GET_CONTAINER_TAG = "GET_CONTAINER_TAG";
export const GET_CONTAINER_TAG_SUCCESS = "GET_CONTAINER_TAG_SUCCESS";
export const GET_CONTAINER_TAG_FAILURE = "GET_CONTAINER_TAG_FAILURE";

export const GET_SINGLE_CONTAINER_TAG = "GET_SINGLE_CONTAINER_TAG";
export const GET_SINGLE_CONTAINER_TAG_SUCCESS = "GET_SINGLE_CONTAINER_TAG_SUCCESS";
export const GET_SINGLE_CONTAINER_TAG_FAILURE = "GET_SINGLE_CONTAINER_TAG_FAILURE";

export const EDIT_CONTAINER_TAG = "EDIT_CONTAINER_TAG";
export const EDIT_CONTAINER_TAG_SUCCESS = "EDIT_CONTAINER_TAG_SUCCESS";
export const EDIT_CONTAINER_TAG_FAILURE = "EDIT_CONTAINER_TAG_FAILURE";

export const DELETE_CONTAINER_TAG = "DELETE_CONTAINER_TAG";
export const DELETE_CONTAINER_TAG_SUCCESS = "DELETE_CONTAINER_TAG_SUCCESS";
export const DELETE_CONTAINER_TAG_FAILURE = "DELETE_CONTAINER_TAG_FAILURE";
