/* eslint-disable no-duplicate-case */
import * as actionTypes from "./actionTypes";

const initialState = {
  error: false,
  errorMessage: "",
  message: "",
  loading: false,
  geoTargetData: [],
  currentPage: 1,
  dataPerPage: 20,
  totalData: 192,
  totalPages: 1,
  canonicalName: [],
  editData: {},
  searchData: [],
  targetTypes: [],
  countryList: [],
};

const GeoTargetsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_GEO_TARGETS_LOADING:
      return {
        ...state,
        loading: true,
        error: false,
        errorMessage: "",
      };
    case actionTypes.GET_GEO_TARGETS_SUCCESS:
      return {
        ...initialState,
        geoTargetData: action.payload.list,
        currentPage: action.payload.currentPage,
        dataPerPage: action.payload.dataPerPage,
        totalData: action.payload.totalData,
        totalPages: action.payload.totalPages,
      };
    case actionTypes.GET_GEO_TARGETS_FAILURE:
      return {
        ...initialState,
        error: true,
        errorMessage: action.errorMessage,
      };
    case actionTypes.GET_TARGET_TYPES_SUCCESS:
      return {
        ...initialState,
        targetTypes: action.payload,
      };
    case actionTypes.GET_TARGET_TYPES_FAILURE:
      return {
        ...initialState,
        error: true,
        errorMessage: action.errorMessage,
      };
    case actionTypes.DELETE_SELECTED_GEOTARGETS_SUCCESS:
      return {
        ...state,
        loading: false,
        message: action.payload,
      };
    case actionTypes.DELETE_SELECTED_GEOTARGETS_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.errorMessage,
      };

    case actionTypes.DELETE_ALL_SELECTED_GEOTARGETS_SUCCESS:
      return {
        ...state,
        loading: false,
        message: action.payload,
      };
    case actionTypes.DELETE_ALL_SELECTED_GEOTARGETS_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.errorMessage,
      };

    case actionTypes.TOGGLE_GEO_TARGETS_SUCCESS:
      return {
        ...state,
        loading: false,
        message: action.payload,
      };

    case actionTypes.GET_CANONICAL_NAME_SUCCESS:
      return {
        ...state,
        loading: false,
        canonicalName: action.payload,
      };
    case actionTypes.GET_CANONICAL_NAME_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.errorMessage,
      };

    case actionTypes.SEARCH_GEO_TARGET_SUCCESS:
      return {
        ...state,
        loading: false,
        searchData: action.payload,
      };
    case actionTypes.SEARCH_GEO_TARGET_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.errorMessage,
      };
    case actionTypes.IMPORT_GEOTARGETS_CSV_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.errorMessage,
      };
    case actionTypes.DELETE_SELECTED_GEOTARGETS_SUCCESS:
      return {
        ...state,
        loading: false,
        message: action.payload,
      };
    case actionTypes.DELETE_SELECTED_GEOTARGETS_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.errorMessage,
      };
    case actionTypes.GET_CANONICAL_NAME_SUCCESS:
      return {
        ...state,
        loading: false,
        canonicalName: action.payload,
      };
    case actionTypes.GET_CANONICAL_NAME_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.errorMessage,
      };
    case actionTypes.GET_SINGLE_GEO_TARGET_SUCCESS:
      return {
        ...state,
        loading: false,
        editData: action.payload,
      };
    case actionTypes.GET_COUNTRIES_SUCCESS:
      return {
        ...state,
        loading: false,
        countryList: action.payload,
      };
    case actionTypes.GET_COUNTRIES_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.errorMessage,
      };
    default:
      return state;
  }
};

export default GeoTargetsReducer;
