import { all, call, put, takeLatest } from "redux-saga/effects";
import * as actionTypes from "./actionTypes";
import APIV2 from "../../Axios/AxiosV2";
import { toast } from "react-toastify";
import Papa from "papaparse";

function* getFilesSaga({ payload }) {
  try {
    yield put({ type: actionTypes.SET_FILES_DATA_LOADING });
    const getData = yield APIV2.get("/file-manager/all", { params: payload });
    if (getData) {
      yield put({
        type: actionTypes.GET_FILES_SUCCESS,
        payload: getData.data.data,
      });
    } else {
      yield put({
        type: actionTypes.GET_FILES_FAILURE,
        errorMessage: getData.data.message,
      });
    }
  } catch (error) {
    yield put({
      type: actionTypes.GET_FILES_FAILURE,
      errorMessage: "Unable to export.",
    });
  }
}
function* deleteFileSaga({ id }) {
  try {
    yield put({ type: actionTypes.SET_FILES_DATA_LOADING });
    const { data } = yield APIV2.post("/file-manager/delete", { id });
    if (data.success) {
      toast.success("File deleted successfully.");
      yield put({ type: actionTypes.GET_FILES });
      yield put({
        type: actionTypes.DELETE_FILE_SUCCESS,
        payload: "File deleted successfully.",
      });
    } else {
      toast.error("Unable to delete File.");
      yield put({
        type: actionTypes.DELETE_FILE_FAILURE,
        errorMessage: "Unable to delete File.",
      });
    }
  } catch (error) {
    toast.error("Unable to delete File.");
    yield put({
      type: actionTypes.DELETE_FILE_FAILURE,
      errorMessage: "Unable to delete File.",
    });
  }
}

function* editFileSaga({ payload }) {
  try {
    yield put({ type: actionTypes.SET_FILES_DATA_LOADING });
    const { data } = yield APIV2.post(`/file-manager/rename/${payload.id}`, {
      name: payload.name,
    });
    if (data.success) {
      toast.success("Rename successfully");
      yield put({ type: actionTypes.GET_FILES });
      yield put({
        type: actionTypes.EDIT_FILE_SUCCESS,
        payload: data.data,
      });
    } else {
      toast.error(data.data.message);
      yield put({
        type: actionTypes.EDIT_FILE_FAILURE,
        errorMessage: data.data.message,
      });
    }
  } catch (error) {
    yield call(
      toast.error,
      error.response?.data?.message || "Unable To Edit File Name"
    );
    yield put({
      type: actionTypes.EDIT_FILE_FAILURE,
      errorMessage: "Unable to edit File Name",
    });
  }
}

function* fetchCSVUrlSaga(url){
  try {
    yield put({ type: actionTypes.SET_FILES_DATA_LOADING });
    const { data } = yield APIV2.get(url.url);
    const text = data
    if(data){
      const { data } = Papa.parse(text, { header: true });
      const obj = {tableHeader:Object.keys(data[0]),tableData:Object.values(data[0])}
      yield put({
          type: actionTypes.FETCH_URL_SUCCESS,
          payload: obj,
        });
    }else{
      toast.error(data.message);
      yield put({
        type: actionTypes.FETCH_URL_FAILURE,
        errorMessage: data.message,
      });
    }
  } catch (error) {
    toast.error("Csv Not found");
    yield put({
      type: actionTypes.FETCH_URL_FAILURE,
      errorMessage: "Unable to fetch this File",
    });
  }
}

function* FileManagerSaga() {
  yield all([
    takeLatest(actionTypes.GET_FILES, getFilesSaga),
    takeLatest(actionTypes.DELETE_FILE, deleteFileSaga),
    takeLatest(actionTypes.EDIT_FILE, editFileSaga),
    takeLatest(actionTypes.FETCH_URL, fetchCSVUrlSaga),
  ]);
}

export default FileManagerSaga;
