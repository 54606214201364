import React from 'react'
import { Col, Row } from 'react-bootstrap'
import AccessDeniedImage from '../Assets/Images/403.svg'

function AccessDenied() {
  return (
    <div style={{minHeight:'100vh',minWidth:'100vw',display:'flex',justifyContent:'center',alignItems:'center',}}>
        <Row className='card'  style={{maxHeight:'700px',maxWidth:'70%',padding:'10px',display:'flex',justifyContent:'center',alignItems:'center',flexDirection:'row'}} >
            <Col md={4}>
                <div>
                    <img style={{maxWidth:'300px',objectFit:'contain'}} src={AccessDeniedImage} alt="" />
                </div>
            </Col>
            <Col md={7}>
                <h3>Access Denied</h3>
                <h1 style={{color:'#5c6bc0'}}>403 Forbidden</h1>
                <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Consequatur tempora repudiandae consequuntur harum esse, voluptatem nulla pariatur quaerat. Aperiam, pariatur!</p>
            </Col>
        </Row>    
    </div>
  )
}

export default AccessDenied