import * as actionTypes from "./actionTypes";

const initialState = {
  error: false,
  errorMessage: "",
  loading: false,
  advertiserFetchData: [],
  getAdvertisers: [],
  _id: "",
  displayName: "",
  email: "",
  uid: "",
  type: "",
  employee_type: "",
  permissions: {},
  advertiserDetails:{},
  shouldRedirect: false,
  createdAdvertiser: {},
};

const AdvertiserData = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_ADVERTISERS_LOADING:
      return {
        ...state,
        loading: true,
        error: false,
        errorMessage: "",
      };
    case actionTypes.SET_ADVERTISERS_REDIRECT_TRUE:
      return {
        ...state,
        loading: false,
        shouldRedirect: true,
      };
      case actionTypes.SET_ADVERTISERS_REDIRECT_FALSE_SUCCESS:
        return {
          ...state,
          loading: false,
          shouldRedirect: false,
          createdAdvertiser: {}
        };

    case actionTypes.FETCH_ADVERTISERS_SUCCESS:
      return {
        ...state,
        loading: false,
        advertiserFetchData: action.payload,
      };
    case actionTypes.FETCH_ADVERTISERS_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.errorMessage,
      };

    case actionTypes.GET_ADVERTISERS_SUCCESS:
      return {
        ...state,
        loading: false,
        getAdvertisers: action.payload,
      };

    case actionTypes.GET_ADVERTISERS_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.errorMessage,
      };

    case actionTypes.SHOPPING_OFFER_ADVERTISERS_SUCCESS:
      return {
        ...state,
        loading: false,
        createdAdvertiser: action.payload
      };
    case actionTypes.SHOPPING_OFFER_ADVERTISERS_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.errorMessage,
      };

    case actionTypes.DELETE_SHOPPING_ADVERTISER_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.DELETE_SHOPPING_ADVERTISER_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.errorMessage,
      };

    case actionTypes.SIGNUP_ADVERTISER_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case actionTypes.SIGNUP_ADVERTISER_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.errorMessage,
      };
    case actionTypes.GET_ACCOUNT_INFO_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case actionTypes.GET_ACCOUNT_INFO_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.errorMessage,
      };
    case actionTypes.GET_OOB_CONFIRMATION_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case actionTypes.GET_OOB_CONFIRMATION_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.errorMessage,
      };
    case actionTypes.VERIFY_ADVERTISER_SUCCESS:
      return {
        ...state,
        loading: false,
        _id: action.payload._id,
        displayName:action.payload.displayName,
        email:action.payload.email,
        uid:action.payload.uid,
        type:action.payload.type,
        employee_type:action.payload.employee_type,
        permissions:action.payload.permissions.reduce((a, v) => ({ ...a, [v]: true}), {}) ,
      };

    case actionTypes.VERIFY_ADVERTISER_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.errorMessage,
      };

    case actionTypes.GET_ADVERTISER_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        advertiserDetails: action.payload,
      };

    case actionTypes.GET_ADVERTISER_BY_ID_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.errorMessage,
      };

    default:
      return state;
  }
};

export default AdvertiserData;
