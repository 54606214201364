import * as actionTypes from "./actionTypes";

const initialState = {
  error: false,
  errorMessage: "",
  message: "",
  loading: false,
  selectedAudit: {},
  selectedAuditID:"",
  AuditList: [],
  page: 1,
  totalPages: 1,
  offerAuditList: [],
};

const Audit = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_AUDIT_LOADING:
      return {
        ...state,
        loading: true,
          error: false,
          errorMessage: "",
      };
    case actionTypes.RESET_AUDIT:
      return {
        ...state,
        loading: true,
        error: false,
        errorMessage: "",
        selectedAudit:{},
        AuditList:[],
        offerAuditList:[]
      };
    case actionTypes.GET_AUDIT_BY_OFFER_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        selectedAuditID: action.payload._id,
      };
    case actionTypes.GET_AUDIT_BY_OFFER_ID_FAILURE:
      return {
        ...state,
        loading: false,
          error: true,
          errorMessage: action.errorMessage,
      };
    case actionTypes.GET_AUDIT_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
          selectedAudit: action.payload,
      };
    case actionTypes.GET_AUDIT_BY_ID_FAILURE:
      return {
        ...state,
        loading: false,
          error: true,
          errorMessage: action.errorMessage,
      };
    case actionTypes.GET_AUDIT_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
          AuditList: action.payload.AuditList,
          page: action.payload.page,
          totalPages: action.payload.totalPages,
      };
    case actionTypes.GET_AUDIT_LIST_FAILURE:
      return {
        ...state,
        loading: false,
          error: true,
          errorMessage: action.errorMessage,
      };
    case actionTypes.GET_OFFER_AUDIT_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
          offerAuditList: action.payload,
      };
    case actionTypes.GET_OFFER_AUDIT_LIST_FAILURE:
      return {
        ...state,
        loading: false,
          error: true,
          errorMessage: action.errorMessage,
      };
    case actionTypes.CLEAR_SELECTED_AUDIT:
    return {
      ...state,
      selectedAuditID: "",
    }

    default:
      return state;
  }
};

export default Audit;