

export const CREATE_ROLES = "CREATE_ROLES";
export const CREATE_ROLES_SUCCESS = "CREATE_ROLES_SUCCESS";
export const CREATE_ROLES_FAILURE = "CREATE_ROLES_FAILURE";

export const GET_ROLES_LIST = "GET_ROLES_LIST";
export const GET_ROLES_LIST_SUCCESS = "GET_ROLES_LIST_SUCCESS";
export const GET_ROLES_LIST_FAILURE = "GET_ROLES_LIST_FAILURE";

export const EDIT_ROLE = "EDIT_ROLE";
export const EDIT_ROLE_SUCCESS = "EDIT_ROLE_SUCCESS";
export const EDIT_ROLE_FAILURE = "EDIT_ROLE_FAILURE";

export const DELETE_ROLE = "DELETE_ROLE";
export const DELETE_ROLE_SUCCESS = "DELETE_ROLE_SUCCESS";
export const DELETE_ROLE_FAILURE = "DELETE_ROLE_FAILURE";

export const GET_ROLE_BY_ID = "GET_ROLE_BY_ID";
export const GET_ROLE_BY_ID_SUCCESS = "GET_ROLE_BY_ID_SUCCESS";
export const GET_ROLE_BY_ID_FAILURE = "GET_ROLE_BY_ID_FAILURE";



export const SET_ROLES_LOADING = "SET_ROLES_LOADING";

